import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { BriefcaseIcon, BuildingOfficeIcon, TagIcon, BarsArrowDownIcon } from '@heroicons/react/24/outline'; // Import relevant icons

const SideNavigation = () => {
  const { userInfo, selectedRole, setSelectedRole, content, isMasterUser } = useContext(AppContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
  const [hoveredRole, setHoveredRole] = useState(selectedRole.id);
  const [isNavOpen, setIsNavOpen] = useState(false); // State for navigation open/close
  const location = useLocation();
  const navigate = useNavigate();

  const handleRoleChange = (roleId, roleType) => {
    setSelectedRole({ id: roleId, type: roleType });
    setIsDropdownOpen(false); // Close dropdown after selection
    navigate('/');
  };

  const getRoleName = (roleId, roleType) => {
    const roleList = userInfo?.roleInfo?.[roleType];
    const role = roleList?.find((r) => r.id === roleId);
    return role?.alias || role?.name || 'Unknown';
  };

  const getIconForRole = (roleType) => {
    switch (roleType) {
      case 'spot':
        return <BuildingOfficeIcon className="h-5 w-5 text-gray-500" />; // Example icon for spot
      case 'adv':
        return <TagIcon className="h-5 w-5 text-gray-500" />; // Example icon for adv
      default:
        return <BriefcaseIcon className="h-5 w-5 text-gray-500" />; // Default icon
    }
  };

  const isActiveLink = (path) => location.pathname === path;

  //只有这个异步加载动态路由
  const handleAdvClick = () => {
    navigate(`/detail/${content.id}`);
  }

  return (
    <>
      {/* Toggle Button for Small Screens */}
      {!isNavOpen &&
        <BarsArrowDownIcon className="h-6 w-6 bg-white text-gray-800 cursor-pointer fixed top-4 left-0 lg:hidden z-800 -rotate-90 rounded-b-md" onClick={() => setIsNavOpen(true)} />}

      {/* Side Navigation */}
      <div className={`w-64 h-full overflow-y-auto bg-white border-r border-gray-300 fixed z-800 lg:block ${isNavOpen ? 'block' : 'hidden'}`}>

        {/* Logo and Title Section */}
        <div className="p-6 text-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Pladdypus_Long_Banner.jpg`}
            alt="Pladdypus Logo"
            className="w-auto h-auto mx-auto mb-2"
          />
        </div>

        {userInfo && userInfo.user.permissionLevel === 9 ? (
          <div className="text-center p-6">
            <span className="text-lg font-semibold text-gray-800">Admin Account</span>
          </div>
        ) : (
          <>

            <div className="container mx-auto items-center justify-center flex relative cursor-pointer">
              <div className="flex items-center space-x-3 relative" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <div className="flex items-center justify-center bg-white rounded-lg overflow-hidden w-8 h-8 ">
                  {getIconForRole(selectedRole?.type)}
                </div>
                <div className="text-gray-600">
                  {/* {selectedRole?.type?.charAt(0).toUpperCase() + selectedRole?.type?.slice(1)}: {getRoleName(selectedRole?.id, selectedRole?.type)} */}
                  {getRoleName(selectedRole?.id, selectedRole?.type)}
                </div>
              </div>

              {isDropdownOpen && (
                <>
                  {/* Overlay to close dropdown */}
                  <div
                    className="fixed inset-0 bg-transparent z-10"
                    onClick={() => setIsDropdownOpen(false)}
                  ></div>
                  <div className="fixed right-0 sm:right-auto sm:left-64 top-24  lg:top-24 pt-1 px-2 max-w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
                    <div className="py-1">
                      {userInfo?.roleInfoIdCollection?.spot.map((roleId) => (
                        <div
                          key={roleId}
                          className={`block px-2 py-2 text-sm cursor-pointer ${hoveredRole === roleId ? 'bg-gray-200' : 'text-gray-700'}`}
                          onMouseEnter={() => setHoveredRole(roleId)}
                          onMouseLeave={() => setHoveredRole(null)}
                          onClick={() => handleRoleChange(roleId, 'spot')}
                        >
                          Venue: {getRoleName(roleId, 'spot')}
                        </div>
                      ))}
                      {userInfo?.roleInfoIdCollection?.adv.map((roleId) => (
                        <div
                          key={roleId}
                          className={`block px-2 py-2 text-sm cursor-pointer ${hoveredRole === roleId ? 'bg-gray-200' : 'text-gray-700'}`}
                          onMouseEnter={() => setHoveredRole(roleId)}
                          onMouseLeave={() => setHoveredRole(null)}
                          onClick={() => handleRoleChange(roleId, 'adv')}
                        >
                          Adv: {getRoleName(roleId, 'adv')}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {/* Navigation Section */}
        <nav className="mt-6 px-6 py-2">
          <ul className="space-y-2">

            {userInfo && userInfo.user.permissionLevel !== 9 && (

              <div>

                <li className={`rounded-lg ${isActiveLink('/') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/" className="text-gray-800 text-sm block px-6 py-2">Dashboard</Link>
                </li>

                <li className={`rounded-lg ${(isActiveLink('/landingpage') || location.pathname.includes('/landingpage')) ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/landingpage" className="text-gray-600 text-sm block px-6 py-2">
                    {selectedRole?.type === 'spot' ? 'Pages Setting' : 'Advertisement'}
                  </Link>
                </li>

                <li className={`rounded-lg ${(isActiveLink('/profile') || location.pathname.includes('/profile')) ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/profile" className="text-gray-800 text-sm block px-6 py-2">Account Setting</Link>
                </li>

                <li className={`rounded-lg ${(isActiveLink('/content') || location.pathname.includes('/detail')) ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  {/* 很挫的嵌套三元 没办法了qaq .... 6 */}
                  {
                    selectedRole.type !== 'adv' ?
                      <Link to="/content" className="text-gray-800 text-sm block px-6 py-2">My Concierge Content</Link>
                      :
                      <button onClick={handleAdvClick} className="text-gray-800 text-sm block px-6 py-2">Ad detail</button>
                  }
                </li>

                <li className={`rounded-lg ${isActiveLink('/analytics') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/analytics" className="text-gray-800 text-sm block px-6 py-2"> Analytics</Link>
                </li>
                {isMasterUser &&
                  <li className={`rounded-lg ${isActiveLink('/rolebinding') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                    <Link to="/rolebinding" className="text-gray-800 text-sm block px-6 py-2"> Role Binding</Link>
                  </li>
                }
              </div>
            )}


            {userInfo && userInfo.user.permissionLevel === 9 && (
              <div>

                <li className={`rounded-lg ${isActiveLink('/dashboard') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/dashboard" className="text-gray-800 text-sm block px-6 py-2">Dashboard</Link>
                </li>

                <li className={`rounded-lg ${isActiveLink('/articleselector') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/articleselector" className="text-gray-600 text-sm block px-6 py-2"> Article Selector </Link>
                </li>

                <li className={`rounded-lg ${isActiveLink('/usermanagement') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/usermanagement" className="text-gray-600 text-sm block px-6 py-2">User Management</Link>
                </li>

                <li className={`rounded-lg ${isActiveLink('/adallocation') ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/adallocation" className="text-gray-600 text-sm block px-6 py-2">Ad Allocation</Link>
                </li>

                <li className={`rounded-lg ${(isActiveLink('/content') || location.pathname.includes('/detail')) ? 'bg-gray-200 text-gray-800' : 'hover:bg-gray-200'}`}>
                  <Link to="/content" className="text-gray-800 text-sm block px-6 py-2">My Concierge Content</Link>
                </li>

              </div>

            )}
          </ul>
        </nav>

      </div>

      {/* Overlay for Small Screens */}
      {isNavOpen && <div className="fixed inset-0 bg-black opacity-50 z-10 lg:hidden" onClick={() => setIsNavOpen(false)}></div>}
    </>
  );
};

export default SideNavigation;