import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import AppContext from '../contexts/AppContext';
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Incorrect username and/or password.")
  const [showPassword, setShowPassword] = useState(false); // 控制密码显示或隐藏
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);
  const { setToken, fetchAllInfo, token, setIsMasterUser } = useContext(AppContext);

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/captcha`);
      const data = await response.json();
      setCaptchaToken(data.captchaToken);
      setCaptchaImage(data.captchaImage);
    } catch (error) {
      console.error('Error fetching captcha:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          captchaText,
          captchaToken,
        }),
      });

      if (!response.ok) {
        const tmpResponse = await response.json()
        setErrorMessage(tmpResponse.message);
        setShowError(true);
        setCaptchaText("");
        fetchCaptcha();
        return;
      }

      const data = await response.json();

      Cookies.set('token', data.token, { expires: 1, secure: false, sameSite: 'Strict' });
      if (data && data.user === email){
        setIsMasterUser(true);
        Cookies.set('isMasterUser', true, { expires: 1, secure: false, sameSite: 'Strict' });
      }


      setToken(data.token);
      fetchAllInfo(data.token);
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      fetchCaptcha(); // Refresh captcha on error
    }
  };

  const handleCaptchaClick = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchCaptcha();
    }, 1000);
  };

  useEffect(() => {
    if (email !== "" || password !== "") setShowError(false);
  }, [email, password]);

  //如果被莫名导航到了login判断是否已经有过login（有token）
  useEffect(() => {
    if (token)
      navigate('/')
  }, [navigate, token])

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100">
      <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover z-0">
        <source src={`${process.env.PUBLIC_URL}/assets/background.mp4`} type="video/mp4" />
      </video>

      <div className="relative z-10 w-full max-w-xs md:max-w-xl ">
        <div className='bg-white px-0 pt-0 pb-2 md:px-6 md:pt-2 md:pb-4'>
          <div className="flex justify-center">
            <img src={`${process.env.PUBLIC_URL}/assets/Pladdypus_Long_Banner.jpg`} className="w-full" alt="Logo" />
          </div>
          <div className='px-4 md:px-6 text-lg text-blue-600 font-semibold pb-2'> Welcome </div>
          <div className="px-4 md:px-6 text-gray-500">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border text-black"
                  required
                />
                <label className="block mb-1">Email</label>
              </div>
              <div className="mb-4 relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 border text-black"
                  required
                />
                <label className="block mb-1">Password</label>
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-3.5 right-4 flex items-center text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>

              </div>
              <div className="mb-4">
                <div className="flex">
                  <div className="w-1/2 pr-2">
                    <img src={captchaImage} alt="Captcha" onClick={handleCaptchaClick} className="cursor-pointer" />
                  </div>
                  <div className="w-1/2 pl-2">
                    <input
                      type="text"
                      value={captchaText}
                      onChange={(e) => setCaptchaText(e.target.value)}
                      className="w-full px-3 py-2 border text-black"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end space-x-4 mb-2">
                <Link to="/forgetpwd" className="text-sm text-black hover:text-blue-800 hover:underline transition duration-200">
                  Forgot password
                </Link>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-800 transition duration-200 font-medium"
              >
                Login
              </button>
            </form>
          </div>
        </div>

        {showError && (
          <div className="bg-red-100 text-red-700 px-4 py-2 md:px-12 md:pt-2 md:pb-4">
            <p className="text-lg font-semibold">Action Required</p>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
