import React, { useContext, useState, useEffect, useRef } from 'react';
import SpotTrafficDashboard from '../components/SpotTrafficDashboard';
import AppContext from '../contexts/AppContext';
import AdvLogDashboard from '../components/AdvLogDashboard';
import ContentLogDashboard from '../components/ContentLogDashboard';
import { FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate

const Dashboard = () => {
  const { selectedRole, content } = useContext(AppContext);

  const navigate = useNavigate(); // React 路由的导航函数

  const selectedRoleType = selectedRole.type;

  const [menuOpen, setMenuOpen] = useState({ landing: false, account: false });

  const landingMenuRef = useRef(null);
  const accountMenuRef = useRef(null);

  const toggleMenu = (type) => {
    setMenuOpen((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const closeMenu = () => {
    setMenuOpen({ landing: false, account: false });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (landingMenuRef.current && !landingMenuRef.current.contains(event.target)) &&
        (accountMenuRef.current && !accountMenuRef.current.contains(event.target))
      ) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <div className="pl-0 lg:pl-64 pt-20">

      <div className="max-w-[90%] mx-auto">
        <div className="text-2xl text-blue-600 font-semibold hidden md:block">
          Dashboard
        </div>
        <div className="p-4 bg-white shadow-md rounded-lg mt-5">

          {selectedRole && selectedRoleType === 'spot' && (
            <div>
              <p className="text-gray-600 mt-4 text-justify md:text-center">
                Welcome to Digital compendium's content management system, where you can manage the content of your web presence as you see fit.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-20 mt-8 max-w-[90%] mx-auto">

                {/* Landing Page Card */}
                <div className="bg-purple-500 text-white rounded-md p-3 relative min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Pages Info</h2>
                  <p className="mt-1 text-sm">Manage your pages info effectively.</p>
                  <div
                    className="absolute top-2 right-2 cursor-pointer mt-2"
                    onClick={() => toggleMenu('landing')}
                    ref={landingMenuRef}
                  >
                    <FaEllipsisV size={20} />
                  </div>
                  {menuOpen.landing && (
                    <div className="absolute top-8 right-2 bg-white text-gray-700 shadow-md rounded-lg p-2 z-30">
                      <ul className='relative z-30'>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => { navigate('/landingpage/HomeInfo'); }}
                        >
                          Branding & Contact
                        </li>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => navigate('/landingpage/homeImages')}
                        >
                          Landing Page
                        </li>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => navigate('/landingpage/serviceImages')}
                        >
                          Services Page
                        </li>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => navigate('/landingpage/Home%20Page%20Quick%20Link')}
                        >
                          Page Quick Links
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* Account Card */}
                <div className="bg-blue-500 text-white rounded-md p-3 relative min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Account</h2>
                  <p className="mt-1 text-sm">Manage account settings and preferences.</p>
                  <div
                    className="absolute top-2 right-2 cursor-pointer mt-2"
                    onClick={() => toggleMenu('account')}
                    ref={accountMenuRef}
                  >
                    <FaEllipsisV size={20} />
                  </div>
                  {menuOpen.account && (
                    <div className="absolute top-8 right-2 bg-white text-gray-700 shadow-md rounded-lg p-2 z-30">
                      <ul className='relative z-30'>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => navigate('/profile/QRcodeAndLink')}
                        >
                          QRcode and WebsiteLink
                        </li>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => navigate('/profile/MapLocation')}
                        >
                          Venue Location
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* Concierge Content Card */}
                <div className="relative bg-orange-500 text-white rounded-md p-3 min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Concierge Content</h2>
                  <p className="mt-1 text-sm">Update or manage your concierge content.</p>
                  <button
                    className="absolute bottom-3 right-3 bg-white text-orange-500 px-3 py-1 rounded-lg hover:bg-gray-100 text-sm z-10 mt-4"
                    onClick={() => navigate('/content')}
                  >
                    View Details
                  </button>
                </div>

                {/* Analytics Card */}
                <div className="relative bg-red-500 text-white rounded-md p-3 min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Analytics Charts</h2>
                  <p className="mt-1 text-sm">View performance analytics.</p>
                  <button
                    className="absolute bottom-3 right-3 bg-white text-red-500 px-3 py-1 rounded-lg hover:bg-gray-100 text-sm z-10 mt-4"
                    onClick={() => navigate('/analytics')}
                  >
                    View Details
                  </button>
                </div>

              </div>
              <div className="p-4 bg-white rounded-lg w-full max-w-[95%] mx-auto mt-8 overflow-y-auto">
                <SpotTrafficDashboard></SpotTrafficDashboard>
              </div>
              <div className="p-4 bg-white rounded-lg w-full max-w-[95%] mx-auto mt-8">
                <ContentLogDashboard></ContentLogDashboard>
              </div>
            </div>
          )}

          {selectedRole && selectedRoleType === 'adv' && (

            <div>

              <p className="text-gray-600 mt-4 text-justify md:text-center">
                Welcome to Digital compendium's content management system, where you can modify your advertisements and view the traffic of each hotel.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-20 mt-8 max-w-[90%] mx-auto">

                {/* Landing Page Card */}
                <div className="bg-purple-500 text-white rounded-md p-3 relative min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Advertisement Setting</h2>
                  <p className="mt-1 text-sm">Manage your advertisements effectively.</p>
                  <div
                    className="absolute top-2 right-2 cursor-pointer mt-2"
                    onClick={() => toggleMenu('landing')}
                    ref={landingMenuRef}
                  >
                    <FaEllipsisV size={20} />
                  </div>
                  {menuOpen.landing && (
                    <div className="absolute top-8 right-2 bg-white text-gray-700 shadow-md rounded-lg p-2 z-30">
                      <ul className='relative z-30'>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => { navigate('/landingpage/Image Modification And Alias'); }}
                        >
                          Main Image and Alia
                        </li>
                        <li
                          className="p-2 hover:bg-gray-200 cursor-pointer"
                          onClick={() => navigate('/landingpage/Specials')}
                        >
                          Specials
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* Account Card */}
                <div className="bg-blue-500 text-white rounded-md p-3 relative min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Account</h2>
                  <p className="mt-1 text-sm">Manage account settings and preferences.</p>
                  <button
                    className="absolute bottom-3 right-3 bg-white text-blue-500 px-3 py-1 rounded-lg hover:bg-gray-100 text-sm z-10 mt-4"
                    onClick={() => navigate('/profile/AccountSetting')}
                    ref={accountMenuRef}
                  >
                    View Details
                  </button>
                </div>

                {/* Concierge Content Card */}
                <div className="relative bg-orange-500 text-white rounded-md p-3 min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Edit Concierge Content</h2>
                  <p className="mt-1 text-sm">Update or manage concierge content.</p>
                  <button
                    className="absolute bottom-3 right-3 bg-white text-orange-500 px-3 py-1 rounded-lg hover:bg-gray-100 text-sm z-10 mt-4"
                    onClick={() => navigate((`/detail/${content.id}`))}
                  >
                    View Details
                  </button>
                </div>

                {/* Analytics Card */}
                <div className="relative bg-red-500 text-white rounded-md p-3 min-h-[15vh]">
                  <h2 className="text-lg font-semibold">Analytics Charts</h2>
                  <p className="mt-1 text-sm">View performance analytics.</p>
                  <button
                    className="absolute bottom-3 right-3 bg-white text-red-500 px-3 py-1 rounded-lg hover:bg-gray-100 text-sm z-10 mt-4"
                    onClick={() => navigate('/analytics')}
                  >
                    View Details
                  </button>
                </div>

              </div>

              <div className="p-4 bg-white rounded-lg max-w-[95%] mx-auto mt-5 overflow-y-auto">
                <AdvLogDashboard></AdvLogDashboard>
              </div>

            </div>

          )}

          {/* <div className="w-full h-8 rounded-lg bg-gray-700"></div> */}
        </div>
      </div>

    </div>
  );
};

export default Dashboard;