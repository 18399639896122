import React, { useContext, useState } from 'react';
import { FaArrowUp, FaArrowDown, FaTrash } from 'react-icons/fa';
import AppContext from '../contexts/AppContext';
import ConfirmModal from '../components/ConfirmModal';
// import Tiptap from './Tiptap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MapComponent from './MapComponent';
import svgIcons from '../utils/svgIconList';
import { bannerLogo } from '../utils/bannerLogo';

const LeafNode = ({ detail, setDetail, handleInputChange, canSwitch, handleImageDelete, handleImageChange }) => {
  const { uploadImage, selectedRole } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchState, setSwitchState] = useState(detail.isLeaf);
  const GoogleMapApiKey = 'AIzaSyD63grvHYISPK4dLfyj5kKmC6MR_wQPJ80';

  const contactOptions = [
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phone' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'WhatsApp', value: 'whatsapp' },
    { label: 'Website', value: 'website' },
  ];

  const baseS3Url = 'https://pladdypusv2.s3.ap-southeast-2.amazonaws.com/public/spot_icons/';

  const handleIconSelect = (icon) => {
    setDetail((prevDetail) => ({
      ...prevDetail,
      iconImage: `${baseS3Url}${icon}`,
      brandImagePrivate: `${icon}`
    }));
  };

  const handleIconClear = () => {
    setDetail((prevDetail) => ({
      ...prevDetail,
      iconImage: '',
      brandImagePrivate: ''
    }));
  };

  // 处理复选框状态变更
  const handleContactCheckboxChange = (contactType) => {
    setDetail(prevDetail => {
      const newContact = { ...prevDetail.contact };
      const keys = Object.keys(newContact);
      if (keys.includes(contactType)) {
        // 已选中则移除
        delete newContact[contactType];
      } else {
        // 未选中则添加（检查上限3个）
        if (keys.length < 3) {
          newContact[contactType] = "";
        }
      }
      return { ...prevDetail, contact: newContact };
    });
  };

  // 处理文本输入框的值变更
  const handleContactValueChange = (contactType, newValue) => {
    setDetail(prevDetail => {
      const newContact = { ...prevDetail.contact };
      if (Object.keys(newContact).includes(contactType)) {
        newContact[contactType] = newValue;
      }
      return { ...prevDetail, contact: newContact };
    });
  };
  const handleSwitch = () => {
    setIsModalOpen(true);
  };

  const confirmSwitch = () => {
    setIsModalOpen(false);
    setSwitchState(!switchState);
    setDetail((prevDetail) => ({
      ...prevDetail,
      isLeaf: false, description: '', bannerImage: '',
      iconImage: '', carouselImages: [], mainText: ''
    }));
  };

  const addCarouselImage = async (e, width, height) => {
    const file = e.target.files[0];
    const imagePublicAddress = await uploadImage(file, width, height);
    const publicName = e.target.name;
    setDetail((prevDetail) => ({
      ...prevDetail,
      [publicName]: (prevDetail[publicName] && prevDetail[publicName].length > 0) ? [...prevDetail[publicName], imagePublicAddress] : [imagePublicAddress],
    }));
  };

  const carouselImageUp = (index, name) => {
    if (index > 0) {
      const publicName = name;
      setDetail((prevDetail) => {
        const newPublicImages = [...prevDetail[publicName]];

        // Swap the images
        [newPublicImages[index - 1], newPublicImages[index]] = [newPublicImages[index], newPublicImages[index - 1]];
        return {
          ...prevDetail,
          [publicName]: newPublicImages,
        };
      });
    }
  };

  const carouselImageDown = (index, name) => {
    setDetail((prevDetail) => {
      const publicName = name;
      const newPublicImages = [...prevDetail[publicName]];

      if (index < newPublicImages.length - 1) {
        // Swap the images
        [newPublicImages[index], newPublicImages[index + 1]] = [newPublicImages[index + 1], newPublicImages[index]];
      }

      return {
        ...prevDetail,
        [publicName]: newPublicImages,
      };
    });
  };

  const removeCarouselImage = (index, name) => {
    const publicName = name;
    setDetail((prevDetail) => {
      const newPublicImages = prevDetail[publicName].filter((_, i) => i !== index);

      return {
        ...prevDetail,
        [publicName]: newPublicImages,
      };
    });
  };

  const handleEditorChange = (newContent) => {
    if (newContent.length < 4000) {
      setDetail((prevDetail) => ({
        ...prevDetail,
        mainText: newContent, // 使用富文本内容更新 mainText
      }));
    }
  };

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],  // 标题大小 (h1, h2, h3, h4, h5, h6, 常规文本)
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],  // 列表和缩进
      [{ 'align': [] }],  // 对齐方式
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],  // 加粗、斜体、下划线、删除线、引用
      [{ 'color': [] }, { 'background': [] }],  // 颜色和背景色
      ['clean']  // 清除格式
    ],
  };

  const handleInputNestedChange = (e, arrayName, index, nestedKey) => {
    const { name, value } = e.target;

    setDetail((prevDetail) => {
      if (arrayName) {
        // 处理数组字段
        return {
          ...prevDetail,
          [arrayName]: prevDetail[arrayName].map((item, i) => (i === index ? value : item)),
        };
      } else if (nestedKey) {
        // 处理嵌套对象字段
        return {
          ...prevDetail,
          [nestedKey]: {
            ...prevDetail[nestedKey],
            [name]: value,
          },
        };
      } else {
        // 处理普通字段
        return {
          ...prevDetail,
          [name]: value,
        };
      }
    });
  };

  const handleLocationSelect = (location) => {
    setDetail((prevDetail) => ({
      ...prevDetail,
      geoLocation: {
        ...prevDetail.geoLocation, // 保留其他字段
        latitude: location.latitude,
        longitude: location.longitude,
      },
    }));
  };

  return (
    <div>
      <div className={`flex items-center justify-between ${canSwitch ? '' : 'hidden'}`}>
        <button
          onClick={handleSwitch}
          className="px-4 text-sm bg-white text-red-500 rounded hover:underline"
        >
          Switch to Directory List?
        </button>
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmSwitch}
        header="Do you want to proceed?"
        content="Switching will clear all data and change to Index mode."
      />
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className='w-full md:w-1/2 p-1'>
          <label className='block mt-2'>
            <span className="block text-sm text-gray-700">
              Name
              <span className='text-xs'> &nbsp;{detail.name ? detail.name.length : 0}/30</span>
            </span>
            <input
              type="text"
              name="name"
              value={detail.name || ''}
              onChange={handleInputChange}
              maxLength={30}
              className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
            />
          </label>
          <label className='block mt-2'>
            <span className="block text-sm text-gray-700">
              Description
              <span className='text-xs'> &nbsp;{detail.description ? detail.description.length : 0}/120</span>
            </span>
            <textarea
              name="description"
              value={detail.description || ''}
              onChange={handleInputChange}
              maxLength={120}
              className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
            />
          </label>
          <label className='block mt-2'>
            <span className="block text-sm text-gray-700">
              Content Body
              <span className='text-xs'> &nbsp;{detail.mainText ? detail.mainText.length : 0}/4000</span>
            </span>
            <div onMouseDown={(e) => { e.stopPropagation(); }}
              onClick={(e) => { e.stopPropagation(); }}
            >
              <ReactQuill
                value={detail.mainText || ''}
                onChange={(newContent) => { handleEditorChange(newContent) }}
                modules={quillModules}
                className="bg-gray-50 rounded-lg shadow-sm"
                maxLength={4000}
              />
            </div>
          </label>


          <div className='w-full p-1'>
            <div className="block mt-2">
              <div className='flex items-center gap-2'>
                <span className="inline-block text-sm text-gray-700">Display Icon</span>
                {detail.iconImage && (
                  <img
                    src={detail.iconImage}
                    alt={detail.iconImage.replace('.svg', '')}
                    className="h-12 w-12 object-contain"
                  />
                )}
                {detail.iconImage && (
                  <FaTrash
                    className="h-4 w-4 text-red-500 cursor-pointer ml-auto"
                    onClick={handleIconClear}
                  />
                )}
              </div>
              <div className="grid grid-cols-6 sm:grid-cols-8 gap-2 mt-2 max-h-60 overflow-y-scroll">
                {svgIcons.map((icon, index) => {
                  const iconUrl = `${baseS3Url}${icon}`;
                  const isSelected = detail.iconImage === iconUrl;
                  return (
                    <div
                      key={index}
                      onClick={() => handleIconSelect(icon)}
                      className={`cursor-pointer p-1 max-w-12 max-h-12
                              ${isSelected ? 'border-2' : 'border'} ${isSelected ? 'border-blue-500' : 'border-gray-300'} rounded-md`}
                    >
                      <img
                        src={iconUrl}
                        alt={icon.replace('.svg', '')}
                        className="p-1 w-full h-full object-contain"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {detail && detail.geoLocation && selectedRole && selectedRole.type !== "spot" && (
            <div>
              {/* The Geolocation for content */}
              <label className='block mt-2'>
                <span className="block text-sm text-gray-700">
                  GeoLocation Name
                  <span className='text-xs'> &nbsp;{detail.geoLocation.name ? detail.geoLocation.name.length : 0}/30</span>
                </span>
                <input
                  type="text"
                  name="name"
                  value={detail.geoLocation.name || ''}
                  onChange={(e) => handleInputNestedChange(e, null, null, 'geoLocation')}
                  maxLength={30}
                  className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
                />
              </label>
              <label className='block mt-2'>
                <span className="block text-sm text-gray-700">
                  GeoLocation Description
                  <span className='text-xs'> &nbsp;{detail.geoLocation.description ? detail.geoLocation.description.length : 0}/60</span>
                </span>
                <textarea
                  name="description"
                  value={detail.geoLocation.description || ''}
                  onChange={(e) => handleInputNestedChange(e, null, null, 'geoLocation')}
                  maxLength={1500}
                  className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
                />
              </label>

              <label className='block mt-2'>
                <span className="block text-sm text-gray-700">
                  GeoLocation Type
                  {/* <span className='text-xs'> &nbsp;{detail.geoLocation.type ? detail.geoLocation.type.length : 0}/30</span> */}
                </span>
                <select
                  name="type"
                  value={detail.geoLocation.type || ''}
                  onChange={(e) => handleInputNestedChange(e, null, null, 'geoLocation')}
                  className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
                >
                  <option value="">Please select</option>
                  <option value="adventure_activities">Adventure Activities</option>
                  <option value="bank">Bank</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="home">Home</option>
                  <option value="other">Other</option>
                  <option value="parks_and_nature">Parks and Nature</option>
                  <option value="restaurant">Restaurant</option>
                  <option value="shop">Shop</option>
                  <option value="souvenirs">Souvenirs</option>
                  <option value="transport">Transport</option>
                </select>
              </label>
              <div>
                <h1>Click the map to set location</h1>
                <MapComponent
                  apiKey={GoogleMapApiKey}
                  initialGeolocation={{
                    latitude: detail.geoLocation.latitude || -37.81485579713169,
                    longitude: detail.geoLocation.longitude || 144.95951682329178,
                    defaultZoom: 12,
                  }}
                  onLocationSelect={handleLocationSelect}
                />
              </div>

            </div>

          )}


        </div>
        <div className='w-full md:w-1/2 p-1'>
          <div className='block mt-2'>
            <span className="block text-sm text-gray-700">Banner Image</span>
            <div className="flex items-center">
              <label className="mt-1 block w-full border-gray-300 items-center">
                <span className="px-2 py-1 bg-gray-100 text-gray-900 rounded-md hover:text-gray-400 border-gray-300 border-2 cursor-pointer">
                  Edit Banner
                </span>
                <input
                  type="file"
                  name="bannerImage"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    const processFile = await bannerLogo(file);
                    const imagePublicAddress = await uploadImage(processFile, 540, 540);
                    const name = e.target.name;
                    setDetail((prevDetail) => ({
                      ...prevDetail,
                      [name]: imagePublicAddress,
                    }));
                  }}
                  accept="image/*"
                  className="hidden"
                />
              </label>
            </div>
          </div>
          {detail.bannerImage && (
            <div className="mt-2 w-full flex justify-between items-center">
              <img
                src={detail.bannerImage}
                alt="sideImage"
                className="w-1/2 shadow-md"
              />
              {/* <a href={detail.bannerImage} download="sideImage.jpg">
                  <FaDownload
                    className="h-6 w-6 z-10 text-blue-500 cursor-pointer"
                  />
                </a> */}
              <FaTrash
                className="h-6 w-6 z-10 text-red-500 cursor-pointer"
                onClick={() => handleImageDelete("bannerImage")}
              />

            </div>
          )}
          <div className='block mt-2'>
            <span className="block text-sm text-gray-700">Carousel Images</span>
            <div className="flex items-center">
              <label className="mt-1 block w-full border-gray-300 items-center">
                <span className="px-2 py-1 bg-gray-100 text-gray-900 rounded-md hover:text-gray-400 border-gray-300 border-2 cursor-pointer">
                  Edit Carousel
                </span>
                <input
                  type="file"
                  name="carouselImages"
                  onChange={(e) => { addCarouselImage(e, 960, 540) }}
                  accept="image/*"
                  className="hidden"
                />
              </label>
            </div>
            <div className="carousel-thumbnails mt-2">
              {detail.carouselImages && detail.carouselImages.map((url, index) => (
                <div key={index} className="carousel-thumbnail flex justify-between items-center mt-2">
                  <img src={url} alt={`Carousel ${index}`} className="w-2/3" />

                  <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-1">
                    <FaArrowUp
                      className="h-6 w-6 text-gray-500 cursor-pointer"
                      onClick={() => carouselImageUp(index, "carouselImages")}
                      alt="Up"
                    />
                    <FaArrowDown
                      className="h-6 w-6 text-gray-500 cursor-pointer"
                      onClick={() => carouselImageDown(index, "carouselImages")}
                      alt="Down"
                    />
                    <FaTrash
                      className="h-6 w-6 text-red-500 cursor-pointer"
                      onClick={() => removeCarouselImage(index, "carouselImages")}
                    />
                  </div>

                </div>
              ))}
            </div>
          </div>
          {selectedRole && selectedRole.type !== "spot" && <div className='block mt-2'>
            <span className="block text-sm text-gray-700">Contacts (Maximum 3)</span>
            <div className=''>
              {contactOptions.map((option) => {
                const isSelected = detail.contact && Object.keys(detail.contact).includes(option.value);
                return (
                  <label key={option.value} className="flex items-center space-x-1 justify-between">
                    <p>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleContactCheckboxChange(option.value)}
                      />
                      <span className="text-sm text-gray-700 pl-2">{option.label}</span>
                    </p>
                    <input
                      type="text"
                      value={(detail.contact && detail.contact[option.value]) || ""}
                      onChange={(e) => handleContactValueChange(option.value, e.target.value)}
                      maxLength={30}
                      className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900 max-w-[70%]"
                      disabled={!isSelected} // 未选中时禁止输入
                    />
                  </label>
                );
              })}
            </div>
          </div>}
          <div className='block mt-2'>
            <span className="block text-sm text-gray-700">Search Tags <span className='text-xs'>(Use ; to separate words)</span>
              <span className='text-xs'> &nbsp;{detail.searchTags ? detail.searchTags.length : 0}/40</span>
            </span>
            <input
              type="text"
              name="searchTags"
              value={detail.searchTags || ''}
              onChange={handleInputChange}
              maxLength={40}
              className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
            />
            <div className=''>

            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

export default LeafNode;
