
import React, { useEffect, useState, useContext } from 'react';
import AppContext from "../contexts/AppContext";
import { useNavigate } from 'react-router-dom';
import ArticlePicker from '../components/ArticlePicker';


const ArticleSelector = () => {

    const { token, userInfo } = useContext(AppContext);
    const navigate = useNavigate();
    const [allArticleDistribution, setAllArticleDistribution] = useState(null);

    useEffect(() => {

        if (!userInfo || !userInfo.user) {
            return;
        }

        if(userInfo){

            const requestBody = JSON.stringify({ email: userInfo.user.email });

            fetch(`${process.env.REACT_APP_API_BASE_URL}/article/avaliablearticlesandselectedarticles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: requestBody
            })
                .then(response => {
                    if (!response.ok) {
                        navigate('/articleselector');
                    }
                    return response.json();
                })
                .then(data => {
                    setAllArticleDistribution(data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });


        }

    }, [token, navigate, userInfo]);

    if (!allArticleDistribution) {
        return <div>Loading...</div>;
    }

    return (
        <div className='pl-0 lg:pl-64 pt-20'>

            <div>
                <ArticlePicker
                    data={allArticleDistribution}
                    token={token} />
            </div>

        </div>
    );
};

export default ArticleSelector;