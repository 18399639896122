import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../contexts/AppContext';
import MapComponent from '../components/MapComponent';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../components/ConfirmModal';
import InformModal from '../components/InformModal';

const InitialNewUser = () => {
  // 从全局 Context 中获取 token 等
  const { token, userInfo } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [accountName, setAccountName] = useState('');
  const navigate = useNavigate();
  const GoogleMapApiKey = 'AIzaSyD63grvHYISPK4dLfyj5kKmC6MR_wQPJ80';

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInformModal, setShowInformModal] = useState(false);

  const [informContent, setInformContent] = useState('');
  const [informType, setInformType] = useState('inform');

  // ---------- 切换 Tab (Venue / Adv) ----------
  const [activeTab, setActiveTab] = useState('venue'); // 'venue' or 'adv'

  // ---------- Venue Data ----------
  const [venueData, setVenueData] = useState({
    email: userInfo?.user.email,
    name: '',
    permissionLevel: '',
    geoLocation: {
      latitude: -37.81485579713169,
      longitude: 144.95951682329178,
      defaultZoom: 15,
    },
    logoImage: '',
    selectedAreaId: '',
    selectedLocationInfo: '',
  });

  // ---------- Advertisement Data ----------
  const [advData, setAdvData] = useState({
    name: '',
    permissionLevel: '',
    spotIds: [], // 存放选中的 spot ID 列表
  });

  // ---------- 从后端获取 Spots，用于给 Advertisement 选择 ----------
  const [spots, setSpots] = useState([]);
  const [areas, setAreas] = useState([]);
  const [locationInfos, setLocationInfos] = useState([]);

  // ---------- 消息提示 ----------
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // ========== 获取 Spots 列表 ==========
  useEffect(() => {

    // Fetch Areas
    const fetchAreas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/areaList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email: userInfo.user.email }),
        });
        const data = await response.json();
        setAreas(data.areas);
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    };

    // Fetch Location Info
    const fetchLocationInfos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/article/articleidandname`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email: userInfo.user.email }),
        });
        const data = await response.json();
        setLocationInfos(data.locationInfoList);
      } catch (error) {
        console.error('Error fetching location infos:', error);
      }
    };

    const fetchSpots = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/spot/getallspotidandname`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setSpots(data);
      } catch (error) {
        console.error('Error fetching spots:', error);
      }
    };

    if (userInfo) {

      fetchAreas();
      fetchLocationInfos();
      fetchSpots();

    }

  }, [token, userInfo]);

  // ========== 创建用户的函数 ==========
  const createUser = async () => {
    // 清空提示
    setErrorMessage('');
    setSuccessMessage('');

    const requestBody = {
      email: email,
      contactPhone: 'phone',
      contactEmail: email,
      contactFirstName: 'firstName',
      contactSecondName: 'secondName',
      permissionLevel: 0,
      AccountName: accountName,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/usersupport/activation/createnewuser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create user.');
      }

      const userData = await response.json();
      setSuccessMessage('User created successfully!');
      return userData.user.id;
    } catch (err) {
      setErrorMessage(`Error creating user: ${err.message}`);
      throw err;
    }
  };

  // ========== 创建 Venue 的函数 ==========
  const createVenue = async (newUserId) => {
    const requestBody = {
      ...venueData,
      userId: newUserId
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/spot/createnewspot`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        throw new Error(
          `Error creating venue: ${errorData.message || 'Unexpected response status'
          }`
        );
      }

      const data = await response.json();
      setSuccessMessage(
        (prev) => `${prev}\nVenue "${data.name}" created successfully!`
      );
      return data;
    } catch (error) {
      setErrorMessage(`Error creating venue: ${error.message}`);
      throw error;
    }
  };

  // ========== 创建 Advertisement 的函数 ==========
  const createAdvertisement = async (newUserId) => {
    const requestBody = {
      userId: newUserId,
      name: advData.name,
      permissionLevel: advData.permissionLevel,
      spotIds: advData.spotIds,
      email: userInfo.user.email
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/adv/createnewadvwithspotids`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status !== 201) {
        const errorData = await response.json();
        throw new Error(
          `Error creating advertisement: ${errorData.message || 'Unexpected response status'
          }`
        );
      }

      const data = await response.json();
      setSuccessMessage(
        (prev) => `${prev}\nAdvertisement "${data.adv.name}" created successfully!`
      );
      return data;
    } catch (error) {
      setErrorMessage(`Error creating advertisement: ${error.message}`);
      throw error;
    }
  };

  // ========== 发送邮件 ==========
  const sendEmailAfterCreation = async (newUserId, createdData) => {

    let emailEndpoint = '';

    if (activeTab === 'venue') {

      return

    } else {

      emailEndpoint = '/usersupport/activation/email/advertisment/newuser';

      const emailContent = {
        email: email,
        accountName: accountName,
        userId: newUserId,
        newAdvertismentName: advData.name,  // or createdData.adv.name
        spotIds: advData.spotIds,
        newAdvId: createdData?.adv?.id,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}${emailEndpoint}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(emailContent),
          }
        );
        const emailResponse = await response.json();
        setSuccessMessage((prev) => `${prev}\nAdvertisement email sent: ${emailResponse.message || ''}`);
      } catch (error) {
        setErrorMessage(`Error sending adv email: ${error.message}`);
      }
    }
  };

  // ========== 最终提交按钮：先创建用户，再创建 Venue/Adv，最后发邮件 ==========
  const handleSubmitAll = async () => {

    setErrorMessage('');
    setSuccessMessage('');

    try {
      // 1. 创建用户
      const newUserId = await createUser();

      // 2. 根据当前 Tab 创建 Venue 或 Advertisement
      let createdData;
      if (activeTab === 'venue') {
        createdData = await createVenue(newUserId);
      } else {
        createdData = await createAdvertisement(newUserId);
      }

      // send email
      await sendEmailAfterCreation(newUserId, createdData);

      return "User & Venue/Advertisment created successfully!";

    } catch (error) {
      console.error('Error in handleSubmitAll:', error);
      throw error;
    }
  };

  // 点击「Create User & ...」按钮时，先打开确认弹窗
  const handleCreateClick = () => {
    setShowConfirmModal(true);
  };

  // 当确认弹窗点击「Confirm」时
  const handleConfirmCreate = async () => {
    // 先关闭确认弹窗
    setShowConfirmModal(false);

    try {
      // 调用你的提交逻辑
      const successMsg = await handleSubmitAll();
      // 成功 => 设置通知文本，类型为 "inform"
      setInformContent(successMsg);
      setInformType('inform');
      setShowInformModal(true);

      // 3 秒后自动跳转，也可以放到 InformModal 里
      setTimeout(() => {
        navigate(-1);
      }, 3000);

    } catch (err) {
      // 失败 => 设置通知文本，类型为 "error"
      setInformContent(`Failed to create: ${err.message}`);
      setInformType('error');
      setShowInformModal(true);
      // 同样可以在这 3 秒后跳转，或者让用户手动关闭再跳转
    }
  };

  // 当通知弹窗关闭时，也返回上一页
  const handleInformClose = () => {
    setShowInformModal(false);
    navigate(-1);
  };

  // ========== 选择场地并更新 advData.spotIds ==========
  const handleSpotCheck = (spotId) => {
    setAdvData((prevData) => {
      const spotIds = prevData.spotIds.includes(spotId)
        ? prevData.spotIds.filter((id) => id !== spotId)
        : [...prevData.spotIds, spotId];
      return {
        ...prevData,
        spotIds,
      };
    });
  };

  // ========== Map 中更新经纬度 ==========
  const handleLocationSelect = (location) => {
    setVenueData((prevData) => ({
      ...prevData,
      geoLocation: {
        latitude: location.latitude,
        longitude: location.longitude,
        defaultZoom: location.zoom
      },
    }));
  };

  return (
    <div className='pl-0 lg:pl-64 pt-20'>
      <div className="p-4 bg-white shadow-md rounded-lg max-w-[1500px] min-h-[650px] mx-auto mt-5 mb-10">

        <h2 className="text-2xl font-bold mb-4 ml-4">Create New User & Venue / Advertisment Form</h2>

        {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
        {successMessage && <div className="text-green-500 mb-4 whitespace-pre-line">{successMessage}</div>}

        {/* ========== 1. 用户信息部分 ========== */}
        <div className="mb-6">
          <div className="flex space-x-4">
            <div className="w-1/2 mx-4 mt-4 ">
              <h1 className="ml-4 mb-4">Account Name</h1>
              <input
                type="text"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                className="w-full p-2 border border-gray-500 rounded-md mb-4"
              />
            </div>
            <div className="w-1/2 mx-4 mt-4 ">
              <h1 className="ml-4 mb-4">Primary Email</h1>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-500 rounded-md mb-4"
              />
            </div>
          </div>
        </div>

        {/* ========== 2. Tab 切换（Spot / Adv） ========== */}
        <div className="flex border-b-2 border-gray-200 mb-6">
          <button
            className={`flex-1 px-4 py-3 text-center font-semibold ${activeTab === 'venue' ? 'bg-gray-200 text-black' : 'bg-white text-gray-700 hover:bg-gray-100'
              } transition duration-300 rounded-l-lg`}
            onClick={() => setActiveTab('venue')}
          >
            Venue
          </button>
          <button
            className={`flex-1 px-4 py-3 text-center font-semibold ${activeTab === 'adv' ? 'bg-gray-200 text-black' : 'bg-white text-gray-700 hover:bg-gray-100'
              } transition duration-300 rounded-r-lg`}
            onClick={() => setActiveTab('adv')}
          >
            Advertisement
          </button>
        </div>

        {/* ========== 3. 填写 Spot 或 Advertisement 信息 ========== */}
        {activeTab === 'venue' && (
          <div className="space-y-4">
            <div className="mx-4 mt-4" >
              <h1 className="ml-4 mb-4">Venue Name</h1>
              <input
                type="text"
                name="name"
                value={venueData.name}
                onChange={(e) => setVenueData({ ...venueData, name: e.target.value })}
                className="w-full p-2 border border-gray-500 rounded-md"
              />
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Offering package</h1>

              <select
                name="permissionLevel"
                value={venueData.permissionLevel}
                onChange={(e) => {
                  setVenueData({ ...venueData, permissionLevel: parseInt(e.target.value, 10) })
                }}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="" disabled>
                  Please select a package
                </option>
                <option value={0}>Professional - 39 AUD per month</option>
                <option value={2}>Premium - 49 AUD per month</option>
              </select>

            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Discover Page Area</h1>
              <select
                name="selectedAreaId"
                value={venueData.selectedAreaId}
                onChange={(e) => setVenueData({ ...venueData, selectedAreaId: e.target.value })}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="">Select an Area</option>
                {areas?.map((area) => (
                  <option key={area["Area Id"]} value={area["Area Id"]}>
                    {area["Area Name"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Linked Article Location</h1>
              <select
                name="selectedLocationInfo"
                value={venueData.selectedLocationInfo}
                onChange={(e) => setVenueData({ ...venueData, selectedLocationInfo: e.target.value })}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="">Select a Location</option>
                {locationInfos?.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            <div className="mx-4 mt-4">
              <h1 className='ml-4 text-md text-gray-700'>Select Venue Location on Map</h1>
              <MapComponent
                apiKey={GoogleMapApiKey}
                initialGeolocation={venueData.geoLocation}
                onLocationSelect={handleLocationSelect}
              />
            </div>

          </div>
        )}

        {activeTab === 'adv' && (
          <div className="space-y-4">
            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Advertisement Name</h1>
              <input
                type="text"
                name="name"
                value={advData.name}
                onChange={(e) => setAdvData({ ...advData, name: e.target.value })}
                className="w-full p-2 border border-gray-500 rounded-md"
              />
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Offering package</h1>
              <select
                name="permissionLevel"
                value={advData.permissionLevel}
                onChange={(e) => {
                  setAdvData({ ...advData, permissionLevel: parseInt(e.target.value, 10) })
                }}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="" disabled>
                  Please select a package
                </option>
                <option value={0}>Commercial - (As Contract) per month</option>
                <option value={2}>Wellfare - Free</option>
              </select>

            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Select Spots for this Advertisement</h1>
              <div className="p-2 border border-gray-500 rounded-md max-h-64 overflow-y-auto">
                {spots.map((spot) => (
                  <label key={spot.id} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={advData.spotIds.includes(spot.id)}
                      onChange={() => handleSpotCheck(spot.id)}
                      className="form-checkbox text-blue-500 h-5 w-5"
                    />
                    <span>{spot.name}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* ========== 4. 最终提交按钮 ========== */}
        <div className="flex justify-end mt-6 mr-10">

          <button
            onClick={() => navigate(-1)}  // 或者你想去别的路由，就改这里
            className="px-4 py-2 mr-4 bg-gray-400 text-white rounded-md hover:bg-gray-500"
          >
            Back To User Management
          </button>

          <button
            onClick={handleCreateClick}
            className="bg-green-600 text-white px-8 py-2 rounded-md hover:bg-green-700"
          >
            Create User & {activeTab === 'venue' ? 'Venue' : 'Advertisement'}
          </button>

        </div>

      </div>

      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmCreate}
        header="Confirm Creation"
        content="Please double-check filled information before completing the creation"
        cancelText="Cancel"
        confirmText="Create"
      />

      <InformModal
        isOpen={showInformModal}
        onClose={handleInformClose}
        content={informContent}
        type={informType}
      />

    </div >
  );
};

export default InitialNewUser;