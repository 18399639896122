import React, { useContext, useState, useEffect } from 'react';
import {
  FaAngleRight,
  FaAngleDown,
  FaPlus,
  FaTrash,
  FaPencilAlt,
  FaLock,
  FaEye,
  FaEyeSlash,
  FaBars
} from 'react-icons/fa';
import { ReactSortable } from 'react-sortablejs';
import AppContext from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../components/ConfirmModal';
import SelectModal from '../components/SelectModal';

// Global object to store expanded node IDs
// TO DO: 这里没有设置超时，长时间使用可能会有问题
const loadExpandedNodes = () => {
  try {
    const storedNodes = localStorage.getItem('expandedNodes');
    return storedNodes ? JSON.parse(storedNodes) : {};
  } catch {
    return {};
  }
};
const saveExpandedNodes = (nodes) => {
  localStorage.setItem('expandedNodes', JSON.stringify(nodes));
};
const expandedNodes = loadExpandedNodes();

/**
 * RecursiveNode 递归节点组件  
 * 主要修改：  
 * - 在子节点渲染处使用 ReactSortable 实现拖拽排序  
 * - 新增拖拽手柄  
 * - 移除原来的上移/下移箭头（排序逻辑改为拖拽）
 */
export const RecursiveNode = ({
  node,
  onDelete,
  onVisible,
  onAdd,
  userId,
  isRoot,
  path,
  updateOrder,
}) => {
  const [expand, setExpand] = useState(Boolean(expandedNodes[node.id]));
  const navigate = useNavigate();

  useEffect(() => {
    // 当 expand 状态变化时，更新 localStorage
    saveExpandedNodes(expandedNodes);
  }, [expand]);

  const handleEditClick = () => {
    navigate(`/detail/${node.id}`);
  };

  const handleAddClick = () => {
    onAdd(node.id);
  };

  const handleExpandToggle = () => {
    if (expand) {
      setExpand(false);
      delete expandedNodes[node.id];
    } else {
      setExpand(true);
      expandedNodes[node.id] = true;
    }
  };

  return (
    <div className="pt-2">
      <div className="flex flex-col lg:flex-row lg:items-center justify-between px-2 lg:px-6 py-2 lg:py-4 border-b border-gray-300">
        {/* 左侧：拖拽手柄 + 展开/折叠图标与节点名称 */}
        <div
          className={`w-full lg:w-8/12 flex items-center space-x-3`}
          style={{
            paddingLeft:
              window.innerWidth >= 1024
                ? `calc(${path.length} * 2rem)`
                : `calc(${path.length} * 1rem)`,
          }}
        >
          {/* 如果节点非叶子，则显示展开/收起箭头 */}
          {!node.isLeaf ? (
            expand ? (
              <FaAngleDown
                className="h-5 w-5 text-blue-600 cursor-pointer"
                onClick={handleExpandToggle}
              />
            ) : (
              <FaAngleRight
                className="h-5 w-5 text-blue-600 cursor-pointer"
                onClick={handleExpandToggle}
              />
            )
          ) : <span className='w-5'></span>}
          {/* 拖拽手柄 */}
          <div className="drag-handle mr-2 cursor-move cursor-pointer">
            <FaBars className="h-5 w-5 text-gray-500" />
          </div>
          <span
            onClick={() =>
              node.isLeaf ? handleEditClick() : handleExpandToggle()
            }
            className="cursor-pointer"
          >
            {node.name}
          </span>
        </div>
        {/* 中间：可见性切换 */}
        <div className="w-auto hidden lg:flex items-center justify-center ">
          {path.length !== 0 &&
            (node.visible ? (
              <FaEye
                className="h-5 w-5 text-gray-500 cursor-pointer"
                onClick={() => onVisible(path)}
              />
            ) : (
              <FaEyeSlash
                className="h-5 w-5 text-gray-500 cursor-pointer"
                onClick={() => onVisible(path)}
              />
            ))}
        </div>
        {/* 右侧：操作按钮 */}
        {path.length !== 0 ? (
          node.editable === userId || isRoot ? (
            <div className="w-full lg:w-3/12 flex items-end space-x-3 justify-end lg:justify-center">
              {/* 原来的上移/下移按钮已移除，排序由拖拽实现 */}
              {!node.isLeaf ? (
                <FaPlus
                  className="h-5 w-5 text-gray-500 cursor-pointer"
                  onClick={() => handleAddClick()}
                />
              ) : (
                <FaPlus className="h-5 w-5 text-white cursor-pointer" />
              )}
              <FaTrash
                className="h-5 w-5 text-red-500 cursor-pointer"
                onClick={() => onDelete(path)}
              />
              <FaPencilAlt
                className="h-5 w-5 text-blue-600 cursor-pointer"
                onClick={() => handleEditClick()}
              />
            </div>
          ) : (
            <div className="w-full lg:w-3/12 flex items-end justify-end lg:justify-center">
              <FaLock className="h-5 w-5 text-gray-500" />
            </div>
          )
        ) : node.editable === userId || isRoot ? (
          <div className="w-full lg:w-3/12 flex items-end justify-end lg:justify-center space-x-3">
            <FaPlus
              className="h-5 w-5 text-gray-500 cursor-pointer"
              onClick={() => handleAddClick()}
            />
            <FaPencilAlt
              className="h-5 w-5 text-blue-600 cursor-pointer"
              onClick={() => handleEditClick()}
            />
          </div>
        ) : (
          <div className="w-full lg:w-3/12 flex items-end justify-end lg:justify-center">
            <FaLock className="h-5 w-5 text-gray-500" />
          </div>
        )}
      </div>
      {/* 若展开且存在子节点，则用 ReactSortable 包裹子节点，实现拖拽排序 */}
      {expand && node.attributes && node.attributes.length > 0 && (
        <ReactSortable
          list={node.attributes}
          setList={(newList) => updateOrder(path, newList)}
          handle=".drag-handle"
          animation={150}
          className=""
        >
          {node.attributes.map((child, index) => (
            <RecursiveNode
              key={child.id}
              node={child}
              path={[...path, index]}
              onDelete={onDelete}
              onVisible={onVisible}
              onAdd={onAdd}
              userId={userId}
              isRoot={isRoot}
              updateOrder={updateOrder}
            />
          ))}
        </ReactSortable>
      )}
    </div>
  );
};

const Content = () => {
  const { content, setContent, token, userInfo } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContentAddOpen, setIsContentAddOpen] = useState(false);
  const [deletePath, setDeletePath] = useState(null);
  const [parentNodeId, setParentNodeId] = useState(null);
  const navigate = useNavigate();

  // 根据路径查找节点，路径为索引数组依次查找 attributes
  const findNode = (path, node) => {
    if (path.length === 0) return node;
    const [first, ...rest] = path;
    return findNode(rest, node.attributes[first]);
  };

  /**
   * 拖拽排序更新函数  
   * 1. 先取出父节点原来的顺序（oldList）  
   * 2. 对比 newList 与 oldList，针对相邻节点顺序变化的部分调用 swap API  
   * 3. 更新父节点的 attributes，并更新整体 content 状态
   */
  const updateOrder = async (path, newList) => {
    // 复制 content 并找到需要更新的父节点
    const newContentCopy = { ...content };
    let parentNode;
    if (path.length === 0) {
      parentNode = newContentCopy;
    } else {
      parentNode = findNode(path, newContentCopy);
    }
    
    // 获取原来的 id 顺序
    const oldOrderIds = (parentNode.attributes || []).map(item => item.id);
    const newOrderIds = newList.map(item => item.id);
    
    // 如果顺序没有变化，则不进行更新
    if (JSON.stringify(oldOrderIds) === JSON.stringify(newOrderIds)) {
      return;
    }
    
    // 更新前端状态：直接将新的顺序赋给父节点的 attributes
    parentNode.attributes = newList;
    setContent(newContentCopy);
  
    // 调用新的 reorder API 接口
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contentupdate/reorder`, {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
           email: userInfo.user.email,
           token_email: userInfo.user.email, // 与后端验证时保持一致
           content_parent_id: parentNode.id,
           content_attribute_ids: newOrderIds
        }),
      });
      if (!response.ok) {
        console.error('Error updating content order');
      }
    } catch (error) {
      console.error('Update order error:', error);
    }
  };
  
  

  const handleDelete = (path) => {
    setDeletePath(path);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    const newContent = { ...content };
    const parentPath = deletePath.slice(0, -1);
    const index = deletePath[deletePath.length - 1];
    const parentNode = findNode(parentPath, newContent);
    const deleteNode = parentNode.attributes[index];
    parentNode.attributes.splice(index, 1);
    setContent(newContent);
    setIsModalOpen(false);
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/contentupdate/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: userInfo.user.email,
        content_id: deleteNode.id,
        content_parent_id: parentNode.id,
      }),
    });
  };

  const handleVisibleChange = async (path) => {
    const newContent = { ...content };
    const node = findNode(path, newContent);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/contentupdate/visibility`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: userInfo.user.email,
          content_id: node.id,
          visible: !node.visible,
        }),
      }
    );
    if (response.ok) {
      node.visible = !node.visible;
      setContent(newContent);
    }
  };

  const handleContentAdd = (nodeId) => {
    setIsContentAddOpen(true);
    setParentNodeId(nodeId);
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-2 lg:pl-64 pl-0 pt-20 pb-16">
      <div className="text-3xl text-blue-600 font-semibold py-4 hidden md:block">
        My Concierge Content
      </div>
      <div className="bg-white shadow-md rounded-lg">
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <div className="w-8/12 font-semibold">Title</div>
          <div className="w-auto hidden lg:block font-semibold text-center">
            Visible
          </div>
          <div className="lg:w-3/12 w-2/5 font-semibold text-center">
            Actions
          </div>
        </div>
        {content && (
          <RecursiveNode
            key={content.id}
            node={content}
            path={[]}
            onDelete={handleDelete}
            onVisible={handleVisibleChange}
            onAdd={handleContentAdd}
            userId={userInfo.user.id}
            isRoot={userInfo.user.permissionLevel === 9}
            updateOrder={updateOrder}
          />
        )}
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        header="Confirm Delete"
        content="Are you sure you want to delete this item?"
      />
      <SelectModal
        isOpen={isContentAddOpen}
        onClose={() => setIsContentAddOpen(false)}
        onLeftClick={() => {
          setIsContentAddOpen(false);
          navigate(`/detail/${parentNodeId}/add/list`);
        }}
        onRightClick={() => {
          setIsContentAddOpen(false);
          navigate(`/detail/${parentNodeId}/add/entry`);
        }}
        header="Select the type of Content"
        content="Choose a directory list to hold entries or a directory entry as a standalone item."
        leftText="Add directory list"
        rightText="Add directory entry"
      />
    </div>
  );
};

export default Content;
