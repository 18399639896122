export const bannerLogo = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const img = new Image();
  
        img.onload = () => {
          // 1. 在临时 canvas 上绘制原图（用于取四角像素）
          const tempCanvas = document.createElement("canvas");
          tempCanvas.width = img.width;
          tempCanvas.height = img.height;
          const tempCtx = tempCanvas.getContext("2d");
          tempCtx.drawImage(img, 0, 0);
          
          // 2. 获取原图像素数据
          let imageData;
          try {
            imageData = tempCtx.getImageData(0, 0, img.width, img.height);
          } catch (err) {
            return reject(new Error("无法获取图片像素数据，请检查图片是否允许跨域读取"));
          }
          const data = imageData.data;
  
          // 辅助函数：获取 (x,y) 点的 [r, g, b]
          const getColorAt = (x, y) => {
            const index = (y * img.width + x) * 4;
            return [data[index], data[index + 1], data[index + 2]];
          };
  
          // 3. 取四角像素
          const topLeft = getColorAt(0, 0);
          const topRight = getColorAt(img.width - 1, 0);
          const bottomLeft = getColorAt(0, img.height - 1);
          const bottomRight = getColorAt(img.width - 1, img.height - 1);
  
          // 4. 计算四角的平均色（取整数）
          const avgColor = [
            Math.round((topLeft[0] + topRight[0] + bottomLeft[0] + bottomRight[0]) / 4),
            Math.round((topLeft[1] + topRight[1] + bottomLeft[1] + bottomRight[1]) / 4),
            Math.round((topLeft[2] + topRight[2] + bottomLeft[2] + bottomRight[2]) / 4)
          ];
  
          // 5. 判断四角像素是否相近
          const threshold = 50; // 可根据实际需求调整
          const corners = [topLeft, topRight, bottomLeft, bottomRight];
          const isSimilar = corners.every((color) => {
            return Math.abs(color[0] - avgColor[0]) < threshold &&
                   Math.abs(color[1] - avgColor[1]) < threshold &&
                   Math.abs(color[2] - avgColor[2]) < threshold;
          });
  
          // 6. 创建最终的 540×540 的 canvas
          const finalSize = 540;
          const finalCanvas = document.createElement("canvas");
          finalCanvas.width = finalSize;
          finalCanvas.height = finalSize;
          const finalCtx = finalCanvas.getContext("2d");
  
          if (isSimilar) {
            // 使用 object-fit: contain 模式
            // 先填充背景为四角平均色
            finalCtx.fillStyle = `rgb(${avgColor[0]}, ${avgColor[1]}, ${avgColor[2]})`;
            finalCtx.fillRect(0, 0, finalSize, finalSize);
  
            // 计算等比缩放，确保图片完整显示在正方形内
            const scale = Math.min(finalSize / img.width, finalSize / img.height);
            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;
            const offsetX = (finalSize - scaledWidth) / 2;
            const offsetY = (finalSize - scaledHeight) / 2;
  
            finalCtx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);
          } else {
            // 使用 object-fit: cover 模式
            // 计算等比缩放，确保正方形被完全覆盖，可能裁剪图片两边
            const scale = Math.max(finalSize / img.width, finalSize / img.height);
            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;
            const offsetX = (finalSize - scaledWidth) / 2;
            const offsetY = (finalSize - scaledHeight) / 2;
  
            finalCtx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);
          }
  
          // 7. 将 canvas 输出为 Blob，再生成新的 File（PNG 格式）
          finalCanvas.toBlob((blob) => {
            if (blob) {
              const processedFile = new File([blob], file.name, { type: "image/png" });
              resolve(processedFile);
            } else {
              reject(new Error("无法从 canvas 创建 Blob"));
            }
          }, "image/png");
        };
  
        img.onerror = () => {
          reject(new Error("图片加载失败，请检查文件格式是否正确"));
        };
  
        // 使用 base64 方式读取文件内容
        img.src = event.target.result;
      };
  
      reader.onerror = (err) => {
        reject(err);
      };
  
      reader.readAsDataURL(file);
    });
  };
  