// src/pages/TempHome.jsx
import React, { useContext } from 'react';
import SpotTrafficAnalysis from '../components/SpotTrafficAnalysis';
import AppContext from '../contexts/AppContext';
import AdvLogAnalysis from '../components/AdvLogAnalysis';
import ContentLogAnalysis from '../components/ContentLogAnalysis';

const Analytics = () => {
  const { selectedRole } = useContext(AppContext);

  const selectedRoleType = selectedRole.type

  return (
    <div className='pl-0 lg:pl-64 pt-20'>
      <div className="flex">
        <div className="text-2xl text-blue-600 font-semibold ml-20 hidden md:block">
        Data analysis charts
        </div>
      </div>

      {selectedRole && selectedRoleType === 'spot' && (
        <div>
          <div className="p-4 bg-white rounded-lg w-full max-w-[90%] mx-auto mt-8 overflow-y-auto">
            <SpotTrafficAnalysis></SpotTrafficAnalysis>
          </div>
          <div className="p-4 bg-white rounded-lg w-full max-w-[90%] mx-auto mt-8">
            <ContentLogAnalysis></ContentLogAnalysis>
          </div>
        </div>
      )}

      {selectedRole && selectedRoleType === 'adv' && (

        <div className=" p-4 bg-white rounded-lg max-w-[90%] mx-auto mt-5 overflow-y-auto">
          <AdvLogAnalysis></AdvLogAnalysis>
        </div>
      )}


    </div>
  );
};

export default Analytics;