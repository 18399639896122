import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import AppContext from '../contexts/AppContext';
import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ContentLogAnalysis = () => {
    const { token, selectedRole, userInfo } = useContext(AppContext);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // 昨天
    const sevenDaysAgo = new Date(yesterday);
    sevenDaysAgo.setDate(yesterday.getDate() - 7); // 七天前
    const [startDate, setStartDate] = useState(sevenDaysAgo); // 默认设置为七天前
    const [endDate, setEndDate] = useState(yesterday); // 默认设置为昨天
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [range, setRange] = useState([0, 10]);
    const [timeRange, setTimeRange] = useState("7"); // 默认为过去 7 天

    const roleArray = userInfo?.roleInfo?.[selectedRole?.type] || [];
    const currentRole = roleArray.find((r) => r.id === selectedRole?.id);
    const permissionLevel = currentRole?.permissionLevel;
    const canShowAdvanced = permissionLevel >= 2;


    const handleTimeRangeChange = (e) => {
        const range = e.target.value;
        setTimeRange(range);

        const newEndDate = new Date(yesterday);
        let newStartDate = new Date(newEndDate);

        if (range === "7") {
            newStartDate.setDate(newEndDate.getDate() - 7);
        } else if (range === "15") {
            newStartDate.setDate(newEndDate.getDate() - 15);
        } else if (range === "30") {
            newStartDate.setMonth(newEndDate.getMonth() - 1);
        } else if (range === "90") {
            newStartDate.setMonth(newEndDate.getMonth() - 3);
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    useEffect(() => {
        const fetchAnalyticsData = async () => {
            if (!startDate || !endDate || !selectedRole?.id) {
                console.error('Missing required parameters:', { startDate, endDate, selectedRole });
                return;
            }

            setLoading(true);

            try {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/analysis/getcontentlogsummary`;

                // 1. 先复制一下 startDate / endDate，免得直接修改它们的 state
                const localStart = new Date(startDate);
                const localEnd = new Date(endDate);

                // 2. 把开始日期修正到当日 00:00:00.000
                localStart.setHours(0, 0, 0, 0);

                // 3. 把结束日期修正到当日 23:59:59.999
                localEnd.setHours(23, 59, 59, 999);

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        spotId: selectedRole.id,
                        startDate: dayjs(localStart).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                        endDate: dayjs(localEnd).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }),
                });

                if (response.ok) {
                    const result = await response.json();
                    setData(result);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAnalyticsData();
    }, [startDate, endDate, selectedRole, token]);

    if (loading) {
        return <div>Loading...</div>;
    }


    const processedContentData = Object.entries(data.reduce((acc, entry) => {
        for (const [name, value] of Object.entries(entry.Content || {})) {
            acc[name] = (acc[name] || 0) + value;
        }
        return acc;
    }, {}))
        .sort((a, b) => b[1] - a[1]); // 按数量降序排列

    const totalContentCount = processedContentData.length;
    const pageSize = 10;

    // 动态生成分页区间
    const rangeOptions = [];
    for (let i = 0; i < totalContentCount; i += pageSize) {
        const start = i + 1;
        const end = Math.min(i + pageSize, totalContentCount);
        rangeOptions.push({ start, end });
    }

    // 当前分页数据
    const currentContentData = processedContentData.slice(range[0], range[1]);
    const contentLabels = currentContentData.map(([name]) => name);
    const contentValues = currentContentData.map(([, value]) => value);

    const handleRangeChange = (event) => {
        const [start, end] = event.target.value.split('-').map(Number);
        setRange([start - 1, end]);
    };

    return (
        <div className="w-full p-2 bg-gray-50 rounded-lg shadow-lg">

            <div className="flex gap-4 items-center">

                {/* 时间范围下拉菜单 */}
                <label className="text-gray-600 font-medium ml-4">
                    Time Range:
                    <select
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                        className="ml-2 p-2 border rounded"
                    >
                        <option value="7">Past 7 Days</option>
                        <option value="15">Past 15 Days</option>
                        <option value="30">Past 1 Month</option>
                        {canShowAdvanced && (
                            <>
                                <option value="90">Past 3 Month</option>
                            </>
                        )}
                    </select>
                </label>
            </div>


            {/* Content Trends */}
            <div className=" w-full bg-gray-50 p-4">
                <h3 className="text-lg font-semibold text-gray-700 text-center mb-4">Content Click Rankings by Selected Time Range

                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart displays the ranking of content (e.g., Westpac Bank under the Bank category) based on the number of clicks within the selected time range, helping you identify the most popular content.
                        </div>
                    </span>

                </h3>
                <div className="flex items-center justify-between mb-4">
                    <label className="text-gray-600 font-medium">
                        Select Range:
                        <select
                            onChange={handleRangeChange}
                            className="ml-2 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        >
                            {rangeOptions.map(({ start, end }) => (
                                <option key={`${start}-${end}`} value={`${start}-${end}`}>
                                    {`${start}-${end}`}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

                {currentContentData.length > 0 ? (
                    <div className="w-full h-[70vh]">
                        <Bar
                            data={{
                                labels: contentLabels,
                                datasets: [
                                    {
                                        label: 'Content Total',
                                        data: contentValues,
                                        backgroundColor: '#54728C',
                                        borderColor: '#2A3E59',
                                        borderWidth: 1,
                                        maxBarThickness: 30, // 控制每个柱子的最大宽度
                                    },
                                ],
                            }}
                            options={{
                                indexAxis: 'y', // 横向条形图
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        onClick: () => { }, // 禁用点击隐藏数据功能
                                    },
                                    datalabels: {
                                        color: '#FFFFFF', // 设置数字颜色为白色
                                        anchor: 'center', // 数字显示在柱子末尾
                                        align: 'right', // 数字与柱子右对齐
                                        font: {
                                            size: 10, // 调整数字字体大小
                                            weight: 'bold', // 设置数字字体加粗
                                        },
                                    },
                                },
                                scales: {
                                    x: {
                                        beginAtZero: true,
                                        title: {
                                            display: true, // 显示标题
                                            text: 'Click counts', // x轴单位
                                            font: {
                                                size: 14, // 设置字体大小
                                                weight: 'bold', // 字体加粗
                                            },
                                            color: '#4B5563', // 设置标题颜色
                                        },
                                    },
                                    y: {
                                        ticks: {
                                            padding: 1,
                                            callback: function (value, index, values) {
                                                const label = this.getLabelForValue(value);
                                                if (window.innerWidth < 768 && label.length > 10) {
                                                    return label.slice(0, 10) + '…';
                                                }
                                                return label;
                                            }
                                        },
                                        categoryPercentage: 2, // 调整每个分类的宽度百分比（越小间距越小）
                                        barPercentage: 0.8, // 调整每个条形的宽度百分比
                                        title: {
                                            display: true, // 显示标题
                                            text: 'Content Title', // y轴单位
                                            font: {
                                                size: 14, // 设置字体大小
                                                weight: 'bold', // 字体加粗
                                            },
                                            color: '#4B5563', // 设置标题颜色
                                        },
                                    }
                                },
                                layout: {
                                    padding: {
                                        top: 5, // 图表顶部的间距
                                        bottom: 5, // 图表底部的间距
                                    },
                                },
                            }}
                        />
                    </div>

                ) : (
                    <p className="text-lg font-semibold text-gray-500 text-center">
                        No content data available for the selected time range.
                    </p>
                )}

            </div>
        </div>
    );
};

export default ContentLogAnalysis;