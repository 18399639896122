import React, { useEffect, useState } from 'react';
import { FaPlus, FaTrash, FaArrowUp, FaArrowDown } from 'react-icons/fa';

const QuickLinkSelector = ({ quickLinkIds = [], spot_id, onQuickLinkIdsChange, token, email }) => {
  const [availableLinks, setAvailableLinks] = useState({});
  const [leafLinks, setLeafLinks] = useState({});
  const [selectedLinks, setSelectedLinks] = useState([...quickLinkIds]);
  const [isSortedAsc, setIsSortedAsc] = useState(true); // 控制排序顺序
  const [searchQuery, setSearchQuery] = useState(''); // 控制available Links的搜索框
  const [selectedSearchQuery, setSelectedSearchQuery] = useState(''); // 控制selected Links的搜索框

  useEffect(() => {
    const fetchQuickLinkNames = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/checkcontentnamebyIds`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(quickLinkIds),
        });

        if (response.ok) {
          const data = await response.json();
          setAvailableLinks(data);
        } else {
          console.error('Failed to fetch quick link names');
        }
      } catch (error) {
        console.error('Error fetching quick link names:', error);
      }
    };

    const fetchLeafContentNames = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/spotwholeleafcontentId`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: email,
            spot_id: spot_id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setLeafLinks(data); // {id: name} 格式
        } else {
          console.error('Failed to fetch leaf content names');
        }
      } catch (error) {
        console.error('Error fetching leaf content names:', error);
      }
    };

    fetchQuickLinkNames();
    fetchLeafContentNames();
  }, [quickLinkIds, spot_id, token, email]);

  const handleAddLink = (id) => {
    if (!selectedLinks.includes(id)) {
      const updatedLinks = [...selectedLinks, id];
      setSelectedLinks(updatedLinks);
      onQuickLinkIdsChange(updatedLinks);  // 更新已选项
    }
  };

  const handleRemoveLink = (id) => {
    const updatedLinks = selectedLinks.filter((linkId) => linkId !== id);
    setSelectedLinks(updatedLinks);
    onQuickLinkIdsChange(updatedLinks);
    // 将删除的链接加回到 availableLinks 中
    if (leafLinks[id]) {
      setAvailableLinks((prevLinks) => ({
        ...prevLinks,
        [id]: leafLinks[id],
      }));
    }
  };

  const moveLinkUp = (index) => {
    if (index > 0) {
      const updatedLinks = [...selectedLinks];
      [updatedLinks[index - 1], updatedLinks[index]] = [updatedLinks[index], updatedLinks[index - 1]];
      setSelectedLinks(updatedLinks);
      onQuickLinkIdsChange(updatedLinks);
    }
  };

  const moveLinkDown = (index) => {
    if (index < selectedLinks.length - 1) {
      const updatedLinks = [...selectedLinks];
      [updatedLinks[index + 1], updatedLinks[index]] = [updatedLinks[index], updatedLinks[index + 1]];
      setSelectedLinks(updatedLinks);
      onQuickLinkIdsChange(updatedLinks);
    }
  };

  const handleSortToggle = () => {
    setIsSortedAsc(!isSortedAsc); // 切换正序或倒序
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSelectedSearchChange = (e) => {
    setSelectedSearchQuery(e.target.value.toLowerCase());
  };

  let mergedLinks = { ...leafLinks, ...availableLinks };

  // 排序并过滤 availableLinks
  let sortedLinks = Object.entries(mergedLinks).sort((a, b) => {
    if (isSortedAsc) {
      return a[1].localeCompare(b[1]);
    } else {
      return b[1].localeCompare(a[1]);
    }
  });
  sortedLinks = sortedLinks.filter(([id, name]) => name.toLowerCase().includes(searchQuery));

  // 过滤出还未选中的 availableLinks
  const filteredLinks = sortedLinks.filter(([id]) => !selectedLinks.includes(id));

  // 过滤 selectedLinks
  const filteredSelectedLinks = selectedLinks.filter((id) => {
    const name = mergedLinks[id];
    return name && name.toLowerCase().includes(selectedSearchQuery);
  });

  return (
    <div className="quick-link-selector">
      <div className="flex flex-col md:flex-row md:space-x-8">
        {/* 左侧：已选择的链接 */}
        <div className="w-full md:w-1/2 md:border-r p-4">
          <h4 className="text-md font-semibold mb-2">Selected Links</h4>
          <input
            type="text"
            placeholder="Search selected links"
            value={selectedSearchQuery}
            onChange={handleSelectedSearchChange}
            className="mb-4 p-2 border border-gray-300 rounded w-full"
          />
          <ul className="space-y-2 h-[90vh] overflow-y-auto p-4">
            {filteredSelectedLinks.map((id, index) => (
              <li key={id} className="flex items-center space-x-2">
                <a href={`${window.location.origin}/detail/${id}`} className="flex-grow text-gray-700 hover:underline">
                  {mergedLinks[id] || id}
                </a>
                <button
                  onClick={() => moveLinkUp(index)}
                  disabled={index === 0}
                  className="px-2 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                >
                  <FaArrowUp />
                </button>
                <button
                  onClick={() => moveLinkDown(index)}
                  disabled={index === selectedLinks.length - 1}
                  className="px-2 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                >
                  <FaArrowDown />
                </button>
                <button
                  onClick={() => handleRemoveLink(id)}
                  className="px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* 右侧：可供选择的链接 */}
        <div className="w-full !ml-0 md:w-1/2 p-4">
          <h4 className="text-md font-semibold mb-2">Available Links</h4>

          <div className="mt-2 flex items-center space-x-4">
            {/* 输入框 */}
            <input
              type="text"
              placeholder="Search available links"
              value={searchQuery}
              onChange={handleSearchChange}
              className="p-2 border border-gray-300 rounded flex-1"
            />
            {/* 按钮 */}
            <button
              onClick={handleSortToggle}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {isSortedAsc ? 'Descend' : 'Ascend'}
            </button>
          </div>

          <div className=" h-[90vh] p-4 overflow-y-auto mt-4">
            {filteredLinks.length > 0 ? (
              <ul className="space-y-2">
                {filteredLinks.map(([id, name]) => (
                  <li key={id} className="flex items-center space-x-2">
                    <a href={`${window.location.origin}/detail/${id}`} className="flex-grow text-gray-700 hover:underline">
                      {name}
                    </a>
                    <button
                      onClick={() => handleAddLink(id)}
                      className="px-2 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                      <FaPlus />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No results found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLinkSelector;
