import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { AiOutlineSortAscending, AiOutlineSortDescending, AiOutlineReload } from 'react-icons/ai';
import InformModal from '../components/InformModal';

const AdAllocation = () => {
    const { token, userInfo } = useContext(AppContext);
    const [spots, setSpots] = useState([]); // venue list
    const [advs, setAdvs] = useState([]);   // adv list
    const [selectedSpot, setSelectedSpot] = useState(null); // selected venue for choosing
    const [selectedAdv, setSelectedAdv] = useState(null);   // selected adv for choosing
    const [spotAds, setSpotAds] = useState([]);             // a venue's all participate advs
    const [advSpots, setAdvSpots] = useState([]);           // a adv's all allocated venues
    const [spotSortOrder, setSpotSortOrder] = useState('natural'); // natural a-z z-a
    const [advSortOrder, setAdvSortOrder] = useState('natural'); // natural a-z z-a
    const [isModalOpen, setIsModalOpen] = useState({ 'isOpen': false, 'content': '', 'type': 'inform' });


    useEffect(() => {
        const fetchSpots = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/getallspotidandname`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setSpots(data);
        };

        const fetchAdvs = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/getalladvidandname`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setAdvs(data);
        };

        fetchSpots();
        fetchAdvs();
    }, [token]);

    const handleSpotClick = async (spot) => {
        setSelectedSpot(spot);
        setSelectedAdv(null); // Ensure only one selection at a time
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/allinsidespot/${spot.id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setSpotAds(data);
    };

    const handleAdvClick = async (adv) => {
        setSelectedAdv(adv);
        setSelectedSpot(null); // Ensure only one selection at a time
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/viewadvpairedspot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ "advId": adv.id, "email": userInfo.user.email }),
        });
        const data = await response.json();
        setAdvSpots(data.pairedSpots);
    };

    const handleSpotSort = (order) => {
        setSpotSortOrder(order);
        const sortFn = (a, b) => {
            if (order === 'a-z') return a.name.localeCompare(b.name);
            if (order === 'z-a') return b.name.localeCompare(a.name);
            return 0;
        };
        setSpots([...spots].sort(sortFn));
    };

    const handleAdvSort = (order) => {
        setAdvSortOrder(order);
        const sortFn = (a, b) => {
            if (order === 'a-z') return a.name.localeCompare(b.name);
            if (order === 'z-a') return b.name.localeCompare(a.name);
            return 0;
        };
        setAdvs([...advs].sort(sortFn));
    };

    const clearSelection = () => {
        setSelectedSpot(null);
        setSelectedAdv(null);
        setSpotAds([]);
        setAdvSpots([]);
    };

    const handleSpotUpdate = async () => {
        const requestBody = {
            email: userInfo.user.email,
            spotId: selectedSpot.id,
            advIds: spotAds.map(ad => ad.id),
        };
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/editspotparticipateadvids`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        });
        if (response.ok) {
            setIsModalOpen({ 'isOpen': true, 'content': 'ads successful binded', 'type': 'inform' });
            clearSelection();
        }
        else setIsModalOpen({ 'isOpen': true, 'content': 'internal error, try again', 'type': 'error' });
    };

    const handleAdvUpdate = async () => {
        const requestBody = {
            email: userInfo.user.email,
            advId: selectedAdv.id,
            spotIds: advSpots.map(spot => spot.id),
        };
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/editadvwithspotids`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        });
        if (response.ok) {
            setIsModalOpen({ 'isOpen': true, 'content': 'ads successful binded', 'type': 'inform' });
            clearSelection();
        }
        else setIsModalOpen({ 'isOpen': true, 'content': 'internal error, try again', 'type': 'error' });
    };

    return (
        <div className="w-full max-w-7xl mx-auto px-2 lg:pl-64 pl-0 pt-20 pb-16 flex gap-2 lg:gap-4">

            {/* Venue List */}
            <div className="flex-1 border rounded-lg shadow bg-white p-2 lg:p-4 overflow-y-auto">
                {selectedAdv ? (
                    <div>
                        <h2 className="text-2xl font-bold border-b pb-2 mb-4">Participating Venues</h2>
                        <ul>
                            {spots.map(spot => (
                                <li
                                    key={spot.id}
                                    className={`cursor-pointer px-2 py-2 border-b hover:bg-gray-50 flex items-center justify-between ${selectedSpot?.id === spot.id ? 'bg-blue-100' : ''}`}
                                    onClick={() => {
                                        setAdvSpots(prevSpots => {
                                            const exists = prevSpots.find(sp => sp.id === spot.id);
                                            if (exists) {
                                                return prevSpots.filter(sp => sp.id !== spot.id);
                                            } else {
                                                return [...prevSpots, { ...spot }];
                                            }
                                        });
                                    }}
                                >
                                    <span>{spot.name}</span>
                                    <input type="checkbox" className="ml-2" checked={advSpots.find((sp) => sp.id === spot.id) || false} readOnly />
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-2xl font-bold border-b pb-2 mb-4">Venues</h2>
                        <div className="flex gap-2 mb-4">
                            <button
                                className={`px-3 py-1 border rounded transition-colors ${spotSortOrder === 'a-z' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                onClick={() => handleSpotSort('a-z')}
                            >
                                <AiOutlineSortAscending />
                            </button>
                            <button
                                className={`px-3 py-1 border rounded transition-colors ${spotSortOrder === 'z-a' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                onClick={() => handleSpotSort('z-a')}
                            >
                                <AiOutlineSortDescending />
                            </button>
                            <button
                                className={`px-3 py-1 border rounded transition-colors ${spotSortOrder === 'natural' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                onClick={() => handleSpotSort('natural')}
                            >
                                <AiOutlineReload />
                            </button>
                        </div>
                        <ul>
                            {spots.map(spot => (
                                <li
                                    key={spot.id}
                                    className={`cursor-pointer px-2 py-2 border-b hover:bg-gray-50 ${selectedSpot?.id === spot.id ? 'bg-blue-100' : ''}`}
                                    onClick={() => handleSpotClick(spot)}
                                >
                                    {spot.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {/* Middle Section - Details */}
            <div className="flex-1 border rounded-lg shadow bg-white p-2 lg:p-4 overflow-y-auto">
                <h2 className="text-2xl font-bold border-b pb-2 mb-4">Details</h2>
                <button
                    className={`mt-2 mb-4 px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition ${!(selectedAdv || selectedSpot) ? 'hidden' : ''}`}
                    onClick={clearSelection}
                >
                    Clear Selection
                </button>
                {selectedSpot && (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">{selectedSpot.name}</h3>
                        <h4 className="text-lg mb-2">Ads in this Venue:</h4>
                        <ul className="list-disc pl-5">
                            {spotAds.map(ad => (
                                <li key={ad.id} className="mb-1">
                                    {ad.name}
                                </li>
                            ))}
                        </ul>
                        <button
                            onClick={handleSpotUpdate}
                            className="mt-4 px-2 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        >
                            Save Advertisers
                        </button>
                    </div>
                )}
                {selectedAdv && (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">{selectedAdv.name}</h3>
                        <h4 className="text-lg mb-2">Paired Venues:</h4>
                        <ul className="list-disc pl-5">
                            {advSpots.map(spot => (
                                <li key={spot.id} className="mb-1">
                                    {spot.name}
                                </li>
                            ))}
                        </ul>
                        <button
                            onClick={handleAdvUpdate}
                            className="mt-4 px-2 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        >
                            Save Venues
                        </button>
                    </div>
                )}
            </div>

            {/* Advertisers List */}
            <div className="flex-1 border rounded-lg shadow bg-white p-2 lg:p-4 overflow-y-auto">
                {selectedSpot ? (
                    <div>
                        <h2 className="text-2xl font-bold border-b pb-2 mb-4">Participating Advertisers</h2>
                        <ul>
                            {advs.map(adv => (
                                <li
                                    key={adv.id}
                                    className={`cursor-pointer px-2 py-2 border-b hover:bg-gray-50 flex items-center ${spotAds.find(ad => ad.id === adv.id) ? 'bg-blue-100' : ''}`}
                                    onClick={() => {
                                        setSpotAds(prevAds => {
                                            const exists = prevAds.find(ad => ad.id === adv.id);
                                            if (exists) {
                                                return prevAds.filter(ad => ad.id !== adv.id);
                                            } else {
                                                return [...prevAds, { ...adv }];
                                            }
                                        });
                                    }}
                                >
                                    <input type="checkbox" className="mr-2" checked={spotAds.find((ad) => ad.id === adv.id) || false} readOnly />
                                    <span>{adv.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-2xl font-bold border-b pb-2 mb-4">Advertisers</h2>
                        <div className="flex gap-2 mb-4">
                            <button
                                className={`px-3 py-1 border rounded transition-colors ${advSortOrder === 'a-z' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                onClick={() => handleAdvSort('a-z')}
                            >
                                <AiOutlineSortAscending />
                            </button>
                            <button
                                className={`px-3 py-1 border rounded transition-colors ${advSortOrder === 'z-a' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                onClick={() => handleAdvSort('z-a')}
                            >
                                <AiOutlineSortDescending />
                            </button>
                            <button
                                className={`px-3 py-1 border rounded transition-colors ${advSortOrder === 'natural' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                onClick={() => handleAdvSort('natural')}
                            >
                                <AiOutlineReload />
                            </button>
                        </div>
                        <ul>
                            {advs.map(adv => (
                                <li
                                    key={adv.id}
                                    className={`cursor-pointer px-2 py-2 border-b hover:bg-gray-50 ${selectedAdv?.id === adv.id ? 'bg-blue-100' : ''}`}
                                    onClick={() => handleAdvClick(adv)}
                                >
                                    {adv.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <InformModal
                isOpen={isModalOpen.isOpen}
                onClose={() => {
                    setIsModalOpen((pre) => ({ ...pre, isOpen: false }))
                }}
                content={isModalOpen.content}
                type={isModalOpen.type}
            />
        </div>
    );
};

export default AdAllocation;
