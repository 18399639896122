import React, { useEffect, useState } from 'react';
import InputWithCounter from './InputWithCounter';
import ConfirmModal from './ConfirmModal';
import { FaPlus, FaTrash, FaArrowUp, FaArrowDown, FaDownload, FaPhone, FaWhatsappSquare, FaBook, FaInstagram, FaInstagramSquare } from 'react-icons/fa';
import { MdEmail, MdOutlineAttachEmail, MdContactPhone, MdOutlineWhatsapp, MdOutlineDescription, MdOutlineMenuBook, MdFacebook } from "react-icons/md";
import { AiOutlineFacebook } from "react-icons/ai";
import { FaSquareXTwitter, FaXTwitter } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";
import FloatingFooter from './FloatingFooter';
import CustomPhoneInput from './CustomPhoneInput';
import ColorPalette from './ColorPalette';
import QuickLinkSelector from './QuickLinkSelector';
import { transparentLogo } from '../utils/transparentLogo';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

const EditLanding = ({ landingInfo = {}, onFileChange, onSlideReorder, message, setMessage, messageType, showLogo = true, showHomeImages = true, showServiceImages = true, activeTab = '', editedTheme }) => {

  const maxSlideImageAllowed = 6;

  const handleIndexChange = (index, direction) => {

    if (!landingInfo || !landingInfo.homeImages) return;

    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= landingInfo.homeImages.length) return;

    const reorderedSlides = [...landingInfo.homeImages];
    const [movedSlide] = reorderedSlides.splice(index, 1);
    reorderedSlides.splice(newIndex, 0, movedSlide);
    onSlideReorder(reorderedSlides);
  };


  const handleAddSlide = (e) => {
    e.preventDefault();

    if (!landingInfo || !landingInfo.homeImages) return;


    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => {
      onFileChange(`homeImages_${landingInfo.homeImages.length}`, e.target.files[0], 'homeImages');
    };
    input.click();
  };

  const handleDeleteSlide = (index, e) => {
    e.preventDefault();

    if (!landingInfo || !landingInfo.homeImages || landingInfo.homeImages.length <= 1) return;

    if (landingInfo.homeImages.length <= 1) return; // 禁用删除按钮当幻灯片只剩一个时
    const updatedSlides = landingInfo.homeImages.filter((_, i) => i !== index);
    onSlideReorder(updatedSlides);

  };


  const handleServiceIndexChange = (index, direction) => {

    if (!landingInfo || !landingInfo.serviceImages) return;

    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= landingInfo.serviceImages.length) return;

    const reorderedSlides = [...landingInfo.serviceImages];
    const [movedSlide] = reorderedSlides.splice(index, 1);
    reorderedSlides.splice(newIndex, 0, movedSlide);
    onSlideReorder(reorderedSlides);
  };


  const handleServiceAddSlide = (e) => {
    e.preventDefault();

    if (!landingInfo || !landingInfo.serviceImages) return;

    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => {
      onFileChange(`serviceImages_${landingInfo.serviceImages.length}`, e.target.files[0], 'serviceImages');
    };
    input.click();
  };

  const handleServiceDeleteSlide = (index, e) => {
    e.preventDefault();

    if (!landingInfo || !landingInfo.serviceImages || landingInfo.serviceImages.length <= 1) return;

    if (landingInfo.serviceImages.length <= 1) return; // 禁用删除按钮当幻灯片只剩一个时
    const updatedSlides = landingInfo.serviceImages.filter((_, i) => i !== index);
    onSlideReorder(updatedSlides);

  };


  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const processedFile = await transparentLogo(file);
        onFileChange('logoImage', processedFile, 'logoImage');
      } catch (error) {
        console.error("Image processing failed:", error);
        setMessage(error.message);
      }
    }
  };

  return (

    <div className="space-y-6">

      {message && (
        <p className={`mb-5 ${messageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
          {message}
        </p>
      )}
      {landingInfo && <div>
        {/* Logo Image Section */}
        <div>
          {showLogo && landingInfo && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-5">
              {/* Logo 图片 */}
              <div className="text-center col-span-1">
                <img src={landingInfo.logoImage} alt="Logo" className="w-auto h-[20vh] mx-auto" style={{ backgroundColor: editedTheme.color }} />
              </div>

              {/* 文件选择按钮 */}
              <div className="col-span-1 flex flex-wrap items-center">
                <input
                  type="file"
                  onChange={(e) => handleLogoChange(e)}
                  className="block w-full max-w-xs text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />

                {/* 小屏幕下，下载图标 */}
                <a
                  href={landingInfo.logoImage}
                  download
                  className="text-blue-500 hover:text-blue-700 flex md:hidden justify-center"
                >
                  <FaDownload />
                </a>

                {/* 大屏幕下，下载图标 */}
                <div className="hidden md:flex justify-center">
                  <a href={landingInfo.logoImage} download className="text-blue-500 hover:text-blue-700">
                    <FaDownload />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Home Images Section */}

        {showHomeImages && landingInfo.homeImages && landingInfo.homeImages.length > 0 && (
          landingInfo.homeImages.map((slide, index) => (

            <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center p-4 border-b border-gray-200">
              {/* 序号 */}
              <div className="flex items-center space-x-2 col-span-1">

                <span className="text-center text-md font-semibold bg-blue-700 text-white rounded-md w-6 h-6 flex items-center justify-center">
                  {index + 1}
                </span>

                <div className="flex items-center justify-between space-x-2">
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={(e) => {
                      e.preventDefault();
                      handleIndexChange(index, -1);
                    }}
                    disabled={index === 0}
                  >
                    <FaArrowUp />
                  </button>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={(e) => {
                      e.preventDefault();
                      handleIndexChange(index, +1);
                    }}
                    disabled={index === landingInfo.homeImages.length - 1}
                  >
                    <FaArrowDown />
                  </button>
                </div>

              </div>

              {/* 图片展示 */}
              <div className="col-span-1 ml-4">
                <img src={slide} alt={`Slide ${index + 1}`} className="w-auto h-[12vh] mx-auto" />
              </div>

              {/* 文件上传 */}
              <div className="col-span-1">
                <input
                  type="file"
                  onChange={(e) => onFileChange(`homeImages_${index}`, e.target.files[0], 'homeImages')}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 ml-8"
                />
              </div>

              {/* 删除和下载 */}
              <div className="col-span-1 flex justify-end md:justify-center space-x-2">
                <button
                  className={`text-red-500 hover:text-red-700 ${landingInfo.homeImages.length === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={(e) => handleDeleteSlide(index, e)}
                  disabled={landingInfo.homeImages.length === 1}
                >
                  <FaTrash />
                </button>
                <a href={slide} download className="text-blue-500 hover:text-blue-700">
                  <FaDownload />
                </a>
              </div>
            </div>


          ))
        )}

        {showHomeImages && (
          <div className="text-center p-4">
            {landingInfo?.homeImages ? (
              landingInfo.homeImages.length < maxSlideImageAllowed ? (
                <button
                  onClick={handleAddSlide}
                  className="inline-flex items-center mt-5 px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaPlus className="mr-2" />
                  Add New Slide Image
                </button>
              ) : landingInfo.homeImages.length >= maxSlideImageAllowed ? (
                <p className="text-red-500">You have reached the maximum of {maxSlideImageAllowed} home images.</p>
              ) : (
                <p className="text-red-500">There are no existing home images.</p>
              )
            ) : activeTab === 'homeImages' && (
              <p className="text-red-500">Loading home images...</p>
            )}
          </div>

        )}


        {/* Service Images Section */}
        {showServiceImages && landingInfo.serviceImages && landingInfo.serviceImages.length > 0 && (
          landingInfo.serviceImages.map((slide, index) => (

            <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center p-4 border-b border-gray-200">
              {/* 序号 */}
              <div className="flex items-center space-x-2 col-span-1">

                <span className="text-center text-md font-semibold bg-blue-700 text-white rounded-md w-6 h-6 flex items-center justify-center">
                  {index + 1}
                </span>

                <div className="flex items-center justify-between space-x-2">
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={(e) => {
                      e.preventDefault();
                      handleServiceIndexChange(index, -1);
                    }}
                    disabled={index === 0}
                  >
                    <FaArrowUp />
                  </button>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={(e) => {
                      e.preventDefault();
                      handleServiceIndexChange(index, +1);
                    }}
                    disabled={index === landingInfo.serviceImages.length - 1}
                  >
                    <FaArrowDown />
                  </button>
                </div>

              </div>

              {/* 图片展示 */}
              <div className="col-span-1 ml-4">
                <img src={slide} alt={`Slide ${index + 1}`} className="w-auto h-[12vh] mx-auto" />
              </div>

              {/* 文件上传 */}
              <div className="col-span-1">
                <input
                  type="file"
                  onChange={(e) => onFileChange(`serviceImages_${index}`, e.target.files[0], 'serviceImages')}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 ml-8"
                />
              </div>

              {/* 删除和下载 */}
              <div className="col-span-1 flex justify-end md:justify-center space-x-2">
                <button
                  className={`text-red-500 hover:text-red-700 ${landingInfo.serviceImages.length === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={(e) => handleServiceDeleteSlide(index, e)}
                  disabled={landingInfo.serviceImages.length === 1}
                >
                  < FaTrash />
                </button>
                <a href={slide} download className="text-blue-500 hover:text-blue-700">
                  < FaDownload />
                </a>
              </div>
            </div>


          ))
        )}

        {showServiceImages && (
          <div className="text-center p-4">
            {landingInfo?.serviceImages ? (
              landingInfo.serviceImages.length < maxSlideImageAllowed ? (
                <button
                  onClick={handleServiceAddSlide}
                  className="inline-flex items-center mt-5 px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaPlus className="mr-2" />
                  Add New Slide Image
                </button>
              ) : landingInfo.serviceImages.length >= maxSlideImageAllowed ? (
                <p className="text-red-500">You have reached the maximum of {maxSlideImageAllowed} service images.</p>
              ) : (
                <p className="text-red-500">There are no existing service images.</p>
              )
            ) : activeTab === 'serviceImages' && (
              <p className="text-red-500">Loading service images...</p>
            )}
          </div>

        )}


      </div>}
    </div>
  );
};



const ModifyContactAndlanding = ({ initialContacts, initialLanding, initialTheme, spotId, token, userInfo }) => {

  const [initialLandingData, setInitialLandingData] = useState(null);
  const [initialContactsData, setInitialConstractsData] = useState(null);
  const [initialThemeData, setInitialThemeData] = useState(null);

  const [editedLanding, setEditedLanding] = useState(null);
  const [editedContacts, setEditedContacts] = useState(null);
  const [editedTheme, setEditedTheme] = useState(null);
  const [message, setMessage] = useState('');

  const [messageType, setMessageType] = useState(null);

  const [hasChanges, setHasChanges] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeTab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    const validTabs = ['homeImages', 'serviceImages', 'HomeInfo', 'Hotel Location', 'Home Page Quick Link'];

    if (!activeTab) {
      navigate('/landingpage/homeImages', { replace: true });
    } else if (activeTab && !validTabs.includes(activeTab)) {
      navigate('/landingpage/homeImages', { replace: true });
    }
  }, [activeTab, navigate]);

  const handleTabChange = (tab) => {
    navigate(`/landingpage/${tab}`);
  };

  useEffect(() => {

    setEditedLanding(initialLanding)
    setInitialLandingData(initialLanding)

  }, [initialLanding]);

  useEffect(() => {

    setEditedContacts(initialContacts)
    setInitialConstractsData(initialContacts)

  }, [initialContacts]);

  useEffect(() => {

    setEditedTheme(initialTheme)
    setInitialThemeData(initialTheme)

  }, [initialTheme]);


  useEffect(() => {

    const hasLandingChanged = JSON.stringify(editedLanding) !== JSON.stringify(initialLandingData);
    const hasContactsChanged = JSON.stringify(editedContacts) !== JSON.stringify(initialContactsData);
    const hasThemeChanged = JSON.stringify(editedTheme) !== JSON.stringify(initialThemeData);

    setHasChanges(hasLandingChanged || hasContactsChanged || hasThemeChanged)

  }, [editedLanding, editedContacts, editedTheme, initialLandingData, initialContactsData, initialThemeData]);


  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
        setMessageType(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // 打开模态框
  const openModal = () => {
    setIsModalOpen(true);
  };

  // 关闭模态框
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 确认保存并执行 handleSave
  const confirmSave = async () => {
    await handleSave();
    closeModal(); // 保存后关闭模态框
    window.location.reload();
    // setHasChanges(false);
  };

  const generalMaxlength = 250;
  const linkMaxlength = 1000;

  const handleInputLandingChange = (field, value) => {
    if (editedLanding[field] !== value) {
      setEditedLanding({
        ...editedLanding,
        [field]: value,
      });
    }
  };

  const handleInputContactsChange = (field, value) => {
    const contactMethod = field.split('_')[0]; // 获得属性名
    const valueOrDescription = field.split('_')[1]; // 获得是 'value' 还是 'description'

    // 确保 contactMethod 和 valueOrDescription 存在于对象中
    if (editedContacts[contactMethod] && editedContacts[contactMethod][valueOrDescription] !== value) {
      setEditedContacts({
        ...editedContacts, // 保留原有数据
        [contactMethod]: {
          ...editedContacts[contactMethod], // 保留嵌套对象中的其他字段
          [valueOrDescription]: value, // 更新指定的字段
        },
      });
    }
  };


  // const handleFileChange = (field, file, type) => {
  //   let width, height;

  //   // Set image dimensions based on the field
  //   if (type === 'logoImage' || type === 'homeImages' || type === 'serviceImages') {
  //     width = 960;
  //     height = 540; // 16:9 ratio
  //   } else if (type === 'bannerImagePublic') {
  //     width = 1165;
  //     height = 500; // 2.33:1 ratio
  //   }

  //   const formData = new FormData();
  //   formData.append('image', file);
  //   formData.append('width', width);
  //   formData.append('height', height);

  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/uploadimage`,
  //     {
  //       method: 'POST',
  //       headers: { 'Authorization': `Bearer ${token}` },
  //       body: formData,
  //     })
  //     .then(response => {
  //       if (!response.ok) {
  //         setMessageType('error');
  //         throw new Error('Failed to upload image');
  //       }
  //       return response.json();
  //     })
  //     .then(data => {

  //       setMessageType('success');
  //       setMessage(data.message);

  //       const fileUrl = data.fileUrl;

  //       // Update Public field
  //       const updatedLanding = _.cloneDeep(editedLanding);

  //       if (field.startsWith('homeImages')) {
  //         const index = parseInt(field.split('_')[1], 10);
  //         updatedLanding.homeImages[index] = fileUrl;
  //         setEditedLanding(updatedLanding);

  //       } else if (field.startsWith('serviceImages')) {
  //         const index = parseInt(field.split('_')[1], 10);
  //         updatedLanding.serviceImages[index] = fileUrl;
  //         setEditedLanding(updatedLanding);

  //       } else {
  //         updatedLanding[field] = fileUrl;
  //         setEditedLanding(updatedLanding);
  //       }

  //     })
  //     .catch(error => {
  //       console.error('Error uploading image:', error);
  //       setMessage('Failed to upload image');
  //       setMessageType('error');
  //     });
  // };

  const handleFileChange = (field, file, type) => {
    // 如果不是 logoImage，使用预设宽高
    if (type !== 'logoImage') {
      let width, height;
  
      if (type === 'homeImages' || type === 'serviceImages') {
        width = 960;
        height = 540; // 16:9
      } else if (type === 'bannerImagePublic') {
        width = 1165;
        height = 500;
      } else {
        // 其他类型也可自行定义或默认
        width = 960;
        height = 540; 
      }
  
      // 创建表单数据
      const formData = new FormData();
      formData.append('image', file);
      formData.append('width', width);
      formData.append('height', height);
  
      // 执行上传
      fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/uploadimage`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            setMessageType('error');
            throw new Error('Failed to upload image');
          }
          return response.json();
        })
        .then(data => {
          setMessageType('success');
          setMessage(data.message);
  
          const fileUrl = data.fileUrl;
          const updatedLanding = _.cloneDeep(editedLanding);
  
          // 按照你之前的逻辑，更新 editedLanding
          if (field.startsWith('homeImages')) {
            const index = parseInt(field.split('_')[1], 10);
            updatedLanding.homeImages[index] = fileUrl;
          } else if (field.startsWith('serviceImages')) {
            const index = parseInt(field.split('_')[1], 10);
            updatedLanding.serviceImages[index] = fileUrl;
          } else {
            updatedLanding[field] = fileUrl;
          }
  
          setEditedLanding(updatedLanding);
        })
        .catch(error => {
          console.error('Error uploading image:', error);
          setMessage('Failed to upload image');
          setMessageType('error');
        });
    }
  
    // 如果是 logoImage，则动态获取实际宽高
    else {
      const img = new Image();
      const objectURL = URL.createObjectURL(file);
      img.src = objectURL;
  
      img.onload = () => {
        // 获取实际宽、高
        const width = img.naturalWidth;
        const height = img.naturalHeight;
  
        URL.revokeObjectURL(objectURL); // 释放内存
  
        const formData = new FormData();
        formData.append('image', file);
        formData.append('width', width);
        formData.append('height', height);
  
        fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/uploadimage`, {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${token}` },
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              setMessageType('error');
              throw new Error('Failed to upload logo image');
            }
            return response.json();
          })
          .then(data => {
            setMessageType('success');
            setMessage(data.message);
  
            const fileUrl = data.fileUrl;
            const updatedLanding = _.cloneDeep(editedLanding);
  
            // 按照你之前的逻辑更新 editedLanding
            if (field.startsWith('homeImages')) {
              const index = parseInt(field.split('_')[1], 10);
              updatedLanding.homeImages[index] = fileUrl;
            } else if (field.startsWith('serviceImages')) {
              const index = parseInt(field.split('_')[1], 10);
              updatedLanding.serviceImages[index] = fileUrl;
            } else {
              updatedLanding[field] = fileUrl;
            }
  
            setEditedLanding(updatedLanding);
          })
          .catch(error => {
            console.error('Error uploading logo image:', error);
            setMessage('Failed to upload image');
            setMessageType('error');
          });
      };
  
      // 如果图片加载出错，也要 revoke URL
      img.onerror = () => {
        URL.revokeObjectURL(objectURL);
        console.error('Failed to load logo image');
        setMessageType('error');
        setMessage('Failed to load logo image');
      };
    }
  };

  const handleSlideReorder = (reorderedSlides) => {
    const updatedLanding = {
      ...editedLanding,
      homeImages: reorderedSlides,
    };

    setEditedLanding(updatedLanding);
  };

  const handleServiceSlideReorder = (reorderedSlides) => {
    const updatedLanding = {
      ...editedLanding,
      serviceImages: reorderedSlides,
    };

    setEditedLanding(updatedLanding);
  };

  const handleColorChange = ({ color, fontColor, font }) => {

    const UpdatedTheme = {
      color: color,
      fontColor: fontColor,
      font: font,
    }
    setEditedTheme(UpdatedTheme);

  };

  const handleQuickLinkIdsChange = (updatedLinks) => {
    setEditedLanding({
      ...editedLanding,
      quickLinkIds: updatedLinks,
    });
  };

  const handleSave = async () => {
    const requestBody = {
      spot_id: spotId,
      contact: editedContacts,
      landing: editedLanding,
      theme: editedTheme
    };

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/changeproperty`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update contacts and landing info');

        }
        else {
          setEditedLanding({ ...requestBody.landing });  // 更新 landing 信息
          setEditedContacts({ ...requestBody.contact });  // 更新 contacts 信息
          setHasChanges(false);  // 保存成功后禁用 Save 按钮

          return response.json();
        }
      })
      .catch(error => {
        console.error('Error updating contacts and landing info:', error);
      });
  };


  return (

    <div className="p-4 bg-white shadow-md rounded-lg max-w-[90%] mx-auto mt-5">

      {/* small screen */}
      <div className="block md:hidden">
        <select
          value={activeTab}
          onChange={(e) => handleTabChange(e.target.value)}
          className="block w-full border-gray-300 rounded-md p-2"
        >

          <option value="HomeInfo">Branding & Contact</option>
          <option value="homeImages">Landing</option>
          <option value="serviceImages">Services</option>
          <option value="Home Page Quick Link">Quick Links</option>

        </select>
      </div>

      {/* large Screen Navigation */}
      <div className="border-b border-gray-200">
        {/* <nav className="flex space-x-4 overflow-x-auto whitespace-nowrap"> */}
        <nav className="hidden md:flex space-x-4">

          <button
            onClick={() => handleTabChange('HomeInfo')}
            className={`px-4 py-2 ${activeTab === 'HomeInfo' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          >
            Branding & Contact
          </button>

          <button
            onClick={() => handleTabChange('homeImages')}
            className={`px-4 py-2 ${activeTab === 'homeImages' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          >
            Landing
          </button>

          <button
            onClick={() => handleTabChange('serviceImages')}
            className={`px-4 py-2 ${activeTab === 'serviceImages' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          >
            Services
          </button>

          <button
            onClick={() => handleTabChange('Home Page Quick Link')}
            className={`px-4 py-2 ${activeTab === 'Home Page Quick Link' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          >
            Quick Links
          </button>

        </nav>
      </div>

      {/* 标签内容 */}
      <div className="mt-6">
        {activeTab === 'homeImages' && editedLanding && (
          <div className='overflow-y-auto'>

            {/* 左侧：Title 和 Description */}
            <div>
              <div className="mb-10">
                <label className="block text-md font-bold text-gray-700">Title</label>
                <InputWithCounter
                  type="text"
                  value={editedLanding.homeTitle}
                  maxLength={generalMaxlength}
                  onChange={(e) => handleInputLandingChange('homeTitle', e.target.value)}
                />
              </div>

              <div>
                <label className="block text-md font-bold text-gray-700">Description</label>
                <InputWithCounter
                  type="text"
                  value={editedLanding.homeDescription}
                  maxLength={generalMaxlength}
                  onChange={(e) => handleInputLandingChange('homeDescription', e.target.value)}
                />
              </div>

            </div>

            {/* Home Images 内容 */}
            <label className="block text-md font-bold text-gray-700 mt-8">Carousel Images</label>
            <EditLanding
              landingInfo={editedLanding}
              onFileChange={handleFileChange}
              onSlideReorder={handleSlideReorder}
              message={message}
              setMessage={setMessage}
              messageType={messageType}
              showLogo={false}
              showHomeImages={true}
              showServiceImages={false}     // turn on Home Carousel
              showBanner={false}
              editedTheme={editedTheme} //纯为了显示背景
            />
          </div>
        )}

        {/* update the service images */}
        {activeTab === 'serviceImages' && editedLanding && (
          <div className='overflow-y-auto'>

            <div>
              <div className="mb-10">
                <label className="block text-md font-bold text-gray-700">Title</label>
                <InputWithCounter
                  type="text"
                  value={editedLanding?.serviceTitle || ''}
                  maxLength={generalMaxlength}
                  onChange={(e) => handleInputLandingChange('serviceTitle', e.target.value)}
                />
              </div>

              <div>
                <label className="block text-md font-bold text-gray-700">Description</label>
                <InputWithCounter
                  type="text"
                  value={editedLanding?.serviceDescription || ''}
                  maxLength={generalMaxlength}
                  onChange={(e) => handleInputLandingChange('serviceDescription', e.target.value)}
                />
              </div>

            </div>

            {/* Services Images 内容 */}
            <label className="block text-md font-bold text-gray-700 mt-8">Carousel Images</label>
            <EditLanding
              landingInfo={editedLanding}
              onFileChange={handleFileChange}
              onSlideReorder={handleServiceSlideReorder}
              message={message}
              setMessage={setMessage}
              messageType={messageType}
              showLogo={false}
              showHomeImages={false}
              showServiceImages={true}  // turn on Services Carousel
              showBanner={false}
              editedTheme={editedTheme}
            />

          </div>

        )}

        {activeTab === 'HomeInfo' && editedLanding && editedContacts &&
          'email' in editedContacts &&
          'phone' in editedContacts &&
          'whatsapp' in editedContacts &&
          'facebook' in editedContacts &&
          'instagram' in editedContacts &&
          'X' in editedContacts &&
          'website' in editedContacts && (
            <div className="mt-4">

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

                <div className="min-w-0 space-y-8">

                  <label className="block text-md font-bold text-gray-700 mb-2 ml-10">
                    Logo Image
                  </label>
                  <EditLanding
                    landingInfo={editedLanding}
                    onFileChange={handleFileChange}
                    onSlideReorder={handleSlideReorder}
                    message={message}
                    setMessage={setMessage}
                    messageType={messageType}
                    showLogo={true}
                    showHomeImages={false}
                    showServiceImages={false}
                    showBanner={false}
                    activeTab={activeTab}
                    editedTheme={editedTheme}
                  />

                  {/* Page Theme Setting and Page Font Colour Setting */}
                  <ColorPalette
                    OriginalTheme={initialTheme}
                    onChange={handleColorChange}
                  />

                </div>

                {/* ========== 右列 (Contacts) ========== */}
                <div className="min-w-0 space-y-8 overflow-y-visible md:overflow-y-auto md:max-h-[87vh]">

                  <h3 className="text-lg font-bold text-gray-700">Contact Information</h3>

                  <div className="space-y-6">

                    {/* Phone 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2 mb-6">
                        <FaPhone className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Phone Number</label>
                      </div>

                      <CustomPhoneInput
                        value={editedContacts.phone.value || ''}
                        onChange={(value) => handleInputContactsChange('phone_value', value)}
                      />

                      <div className="flex items-center space-x-2 mt-6">
                        <MdContactPhone className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Phone Description</label>
                      </div>

                      <InputWithCounter
                        type="phone"
                        value={editedContacts.phone.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('phone_description', e.target.value)}
                      />

                    </div>

                    {/* Email 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2">
                        <MdEmail className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Email Address</label>
                      </div>

                      <InputWithCounter
                        type="email"
                        value={editedContacts.email.value || ''}
                        maxLength={generalMaxlength}
                        onChange={(e) => handleInputContactsChange('email_value', e.target.value)}
                      />

                      <div className="flex items-center space-x-2 mt-6">
                        <MdOutlineAttachEmail className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">
                          Email Description
                        </label>
                      </div>

                      <InputWithCounter
                        type="email"
                        value={editedContacts.email.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('email_description', e.target.value)}
                      />
                    </div>


                    {/* WhatsApp 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2 mb-6">
                        <MdOutlineWhatsapp className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">WhatsApp</label>
                      </div>

                      <CustomPhoneInput
                        value={editedContacts.whatsapp.value || ''}
                        onChange={(value) => handleInputContactsChange('whatsapp_value', value)}
                      />

                      <div className="flex items-center space-x-2 mt-6">
                        <FaWhatsappSquare className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">WhatsApp Description</label>
                      </div>

                      <InputWithCounter
                        type="phone"
                        value={editedContacts.whatsapp.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('whatsapp_description', e.target.value)}
                      />

                    </div>


                    {/* Website 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2 mb-6">
                        <CgWebsite className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Website</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.website.value || ''}
                        maxLength={linkMaxlength}
                        onChange={(e) => handleInputContactsChange('website_value', e.target.value)} />


                      <div className="flex items-center space-x-2 mt-6">
                        <MdOutlineDescription className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Website Description</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.website.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('website_description', e.target.value)} />

                    </div>

                    {/* E-book 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2 mb-6">
                        <FaBook className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">E-Book Website</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.serviceDirectory.value || ''}
                        maxLength={linkMaxlength}
                        onChange={(e) => handleInputContactsChange('serviceDirectory_value', e.target.value)} />


                      <div className="flex items-center space-x-2 mt-6">
                        <MdOutlineMenuBook className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">E-Book Website Description</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.serviceDirectory.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('serviceDirectory_description', e.target.value)} />

                    </div>

                    {/* fackbook 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2 mb-6">
                        <MdFacebook className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Facebook</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.facebook.value || ''}
                        maxLength={linkMaxlength}
                        onChange={(e) => handleInputContactsChange('facebook_value', e.target.value)} />


                      <div className="flex items-center space-x-2 mt-6">
                        <AiOutlineFacebook className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Facebook Description</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.facebook.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('facebook_description', e.target.value)} />

                    </div>

                    {/* Ins 块 */}
                    <div className="mb-4 border-b border-gray-500 pb-8">

                      <div className="flex items-center space-x-2 mb-6">
                        <FaInstagram className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Instagram</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.instagram.value || ''}
                        maxLength={linkMaxlength}
                        onChange={(e) => handleInputContactsChange('instagram_value', e.target.value)} />


                      <div className="flex items-center space-x-2 mt-6">
                        <FaInstagramSquare className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">Instagram Description</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.instagram.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('instagram_description', e.target.value)} />

                    </div>


                    {/* X 块 */}
                    <div>

                      <div className="flex items-center space-x-2 mb-6">
                        <FaSquareXTwitter className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">X (Twitter)</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.X.value || ''}
                        maxLength={linkMaxlength}
                        onChange={(e) => handleInputContactsChange('X_value', e.target.value)} />


                      <div className="flex items-center space-x-2 mt-6">
                        <FaXTwitter className="w-5 h-5 text-gray-600" />
                        <label className="text-sm font-semibold text-gray-700">X (Twitter) Description</label>
                      </div>

                      <InputWithCounter
                        type="text"
                        value={editedContacts.X.description || ''}
                        maxLength={64}
                        onChange={(e) => handleInputContactsChange('X_description', e.target.value)} />

                    </div>



                  </div>
                </div>
              </div>

            </div>
          )}

        {activeTab === 'Home Page Quick Link' && editedLanding && editedLanding.quickLinkIds && (

          <div>
            <QuickLinkSelector
              quickLinkIds={editedLanding.quickLinkIds}
              spot_id={spotId}
              token={token}
              email={userInfo.user.email}
              onQuickLinkIdsChange={handleQuickLinkIdsChange}
            />
          </div>
        )}

        {/* Save Button */}
        <div className="text-right">

          <FloatingFooter hasChanges={hasChanges} onSave={openModal} />

          {/* Confirm Modal */}
          <ConfirmModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onConfirm={confirmSave}
            header="Confirm Save"
            content="Are you sure you want to save the changes?" />
        </div>

      </div>

    </div>


  );
};

export default ModifyContactAndlanding;
