import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Activation = () => {
  const [email, setEmail] = useState('');
  const [accountName, setAccountName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // 控制密码显示或隐藏
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // 控制密码显示或隐藏
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);

  const location = useLocation(); // 获取当前URL信息

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email') || 'Invalid info';
    const accountParam = params.get('account') ? decodeURIComponent(params.get('account')) : 'Invalid info';
    const codeParam = params.get('code') || 'Invalid info';

    setEmail(emailParam);
    setAccountName(accountParam);
    setActivationCode(codeParam);

    fetchCaptcha();
  }, [location.search]);

  const fetchCaptcha = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/captcha`);
      const data = await response.json();
      setCaptchaToken(data.captchaToken);
      setCaptchaImage(data.captchaImage);
    } catch (error) {
      console.error('Error fetching captcha:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('New Password and Confirm Password do not match.');
      setShowError(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/activation/redeemaccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          AccountName: accountName,
          newPassword,
          activation_code: activationCode,
          captchaText,
          captchaToken,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Failed to submit request. Please try again.');
        setShowError(true);
        setShowSuccess(false);
        fetchCaptcha();
        return;
      }

      setShowSuccess(true);
      setShowError(false);
      setErrorMessage('');
      setNewPassword('');
      setConfirmPassword('');
      setCaptchaText('');
      fetchCaptcha(); // 刷新验证码

      // 3秒后跳转到登录页面
      setTimeout(() => {
        navigate('/#/login'); // 导航到登录页面
      }, 5000);


    } catch (error) {
      console.error('Request error:', error);
      setShowError(true);
      setShowSuccess(false);
      setErrorMessage('Failed to submit request. Please try again.');
      fetchCaptcha();
    }
  };

  const handleCaptchaClick = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchCaptcha();
    }, 1000);
  };

  const isFormValid = () => {
    return (
      email !== 'Invalid info' &&
      accountName !== 'Invalid info' &&
      activationCode !== 'Invalid info' &&
      newPassword &&
      confirmPassword &&
      captchaText &&
      newPassword === confirmPassword
    );
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100 rounded-lg">
      <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover z-0">
        <source src={`${process.env.PUBLIC_URL}/assets/background.mp4`} type="video/mp4" />
      </video>

      <div className="relative z-10 w-full max-w-xs md:max-w-xl">
        <div className="bg-white px-0 pt-0 pb-2 md:px-6 md:pt-2 md:pb-4">
          <div className="flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/Pladdypus_Long_Banner.jpg`}
              className="w-full rounded-lg"
              alt="Logo"
            />
          </div>

          <div className="px-6 text-gray-700">
            <div className="flex items-center mb-4">
              {/* <ArrowLeftIcon
                className="h-6 w-6 text-black cursor-pointer hover:text-blue-600 transition duration-200"
                onClick={() => navigate(-1)}
              /> */}
              <h2 className="flex text-lg font-semibold text-blue-600">Account Activation</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-1 mt-1">Email</label>
                <input type="email" value={email} readOnly className="w-full px-3 py-2 border rounded bg-gray-200" />
              </div>
              <div className="mb-4">
                <label className="block mb-1 mt-1">Account Name</label>
                <input type="text" value={accountName} readOnly className="w-full px-3 py-2 border rounded bg-gray-200" />
              </div>
              <div className="mb-4">
                <label className="block mb-1 mt-1">Activation Code</label>
                <input type="text" value={activationCode} readOnly className="w-full px-3 py-2 border rounded bg-gray-200" />
              </div>
              <div className="mb-4 relative">
                <label className="block mb-1 mt-1">New Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 top-7 right-4 flex items-center text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="mb-4 relative">
                <label className="block mb-1 mt-1">Confirm Password</label>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute inset-y-0 top-7 right-4 flex items-center text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="mb-4">
                <div className="flex">
                  <div className="w-1/2 pr-2">
                    <img
                      src={captchaImage}
                      alt="Captcha"
                      onClick={handleCaptchaClick}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="w-1/2 pl-2">
                    <input
                      type="text"
                      value={captchaText}
                      onChange={(e) => setCaptchaText(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                disabled={!isFormValid()}
                className={`w-full py-2 rounded transition duration-200 ${isFormValid() ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-400 text-gray-200 cursor-not-allowed'
                  }`}
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        {showError && (
          <div className="px-4 py-2 bg-red-100 text-red-700 rounded-b-lg md:px-12 md:pt-2 md:pb-4">
            <h3 className="text-lg font-semibold">Action Required</h3>
            <p>{errorMessage}</p>
          </div>
        )}

        {showSuccess && (
          <div className="px-4 py-2 bg-green-100 text-green-700 rounded-b-lg md:px-12 md:pt-2 md:pb-4">
            <h3 className="text-lg font-semibold">Success</h3>
            <p>Activation completed successfully. Please log in with your new credentials. You will be redirected to the login page in 5 seconds. If you are not automatically redirected, try this link ......</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Activation;