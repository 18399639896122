import React, { useState, useCallback, useRef } from 'react';
import { FaMapMarkedAlt } from "react-icons/fa";
import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import _ from 'lodash';

const libraries = ['places']; // Move libraries array out of the component

const MapComponent = ({ apiKey, initialGeolocation, onLocationSelect }) => {

  const initialPosition = { lat: initialGeolocation.latitude, lng: initialGeolocation.longitude }
  const initialZoom = initialGeolocation.defaultZoom
  const [mapCenter, setMapCenter] = useState(initialPosition);
  const [zoom, setZoom] = useState(initialZoom);
  const [selectedPosition, setSelectedPosition] = useState(initialPosition);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [searchBox, setSearchBox] = useState(null);

  const mapRef = useRef(null);
  const mapCenterRef = useRef(initialPosition);
  const zoomRef = useRef(initialZoom);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries, // Use the constant here
  });

  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const newPosition = { lat, lng };
    setSelectedPosition(newPosition);
    setClickedLocation(newPosition);
    onLocationSelect({
      latitude: lat,
      longitude: lng,
      zoom: zoom, // 当前地图的缩放级别
    });
  }, [onLocationSelect, zoom]);

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;

    map.addListener('zoom_changed', _.debounce(() => {
      const newZoom = map.getZoom();
      if (newZoom !== zoomRef.current) {
        zoomRef.current = newZoom;
        setZoom(newZoom);
      }
    }, 500)); // Debounce zoom change to avoid frequent updates

    map.addListener('center_changed', _.debounce(() => {
      const center = map.getCenter();
      if (center) {
        const newCenter = {
          lat: center.lat(),
          lng: center.lng(),
        };
        if (
          newCenter.lat !== mapCenterRef.current.lat ||
          newCenter.lng !== mapCenterRef.current.lng
        ) {
          mapCenterRef.current = newCenter;
          setMapCenter(newCenter);
        }
      }
    }, 500)); // Debounce center change to avoid frequent updates

    map.setOptions({
      draggable: true,
      draggableCursor: 'grab',
      draggingCursor: 'grabbing',
      zoomControl: true,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    });
  }, []);

  const onLoadSearchBox = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places.length > 0) {
        const place = places[0];
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setMapCenter(newCenter);
        setZoom(15); // Zoom in to the selected place
        setSelectedPosition(newCenter);
        setClickedLocation(newCenter);
        onLocationSelect({
          latitude: newCenter.lat,
          longitude: newCenter.lng,
          zoom: 15,
        });
      }
    }
  };

  const handleUseMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newCenter = { lat: latitude, lng: longitude };
          // 更新地图中心、缩放、Marker 等
          setMapCenter(newCenter);
          setZoom(15);
          setSelectedPosition(newCenter);
          setClickedLocation(newCenter);
          // 通知父组件
          onLocationSelect({
            latitude,
            longitude,
            zoom: 15,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
          alert('Unable to get your location.');
        },
        { enableHighAccuracy: true }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };


  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <div className="p-4 mb-10">
      <div className="flex items-center space-x-2 w-full mb-6">
        {/* 让搜索框容器 flex-1，表示自动撑满剩余空间 */}
        <div className="flex-1">
          <StandaloneSearchBox
            onLoad={onLoadSearchBox}
            onPlacesChanged={onPlacesChanged}
          >
            {/* 输入框本身也要设为 w-full 或 flex-1，确保占满容器 */}
            <input
              type="text"
              placeholder="Search for a place"
              className="w-full p-2 border border-gray-500 rounded-md"
            />
          </StandaloneSearchBox>
        </div>

        <div className="flex flex-col items-center">
          <FaMapMarkedAlt
            onClick={handleUseMyLocation}
            className="w-6 h-6 cursor-pointer"
          />
          <span className="text-xs mt-1">Locate Me</span>
        </div>
      </div>

      <div className="h-[50vh] w-full rounded-md overflow-hidden shadow-md">
        <GoogleMap
          center={mapCenter}
          zoom={zoom}
          onClick={handleMapClick}
          onLoad={handleMapLoad}
          mapContainerStyle={{ height: '100%', width: '100%' }}
        >
          {selectedPosition && (
            <Marker position={selectedPosition} />
          )}
        </GoogleMap>
      </div>
      {clickedLocation && (
        <div className="mt-4">
          <p>Clicked Location: Latitude: {clickedLocation.lat}, Longitude: {clickedLocation.lng}</p>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
